export const TranslatedLanguages = Object.freeze({
	ENGLISH: "en",
	GREEK: "el"
});

export const MessageStatus = Object.freeze({
	UNREAD: "unread",
	READ: "read"
});

export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8991/api"
// export const API_URL = "http://172.22.179.237:8087/api"
export const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL || "http://localhost:9000/acrotrace"

export const ONE_HOUR = 3_600_000;
export const ONE_MIN = 60000;
export const ONE_DAY = ONE_HOUR * 24
export const RANGE_LIMIT = ONE_HOUR * 24 * 7 * 2 + ONE_HOUR * 24;

export const RolesDefPage = Object.freeze({
	"super_admin": "dashboard",
	"super_admin:view_only": "dashboard",
	"super_admin:accountant": "superadmin?dashboard",
	"super_admin:accountant_view_only": "superadmin?dashboard",
	"admin:": "dashboard",
	"admin:view_only": "dashboard",
	"admin:accountant": "admin?dashboard",
	"admin:accountant_view_only": "dashboard",
	"manager:view_only": "dashboard",
	"none": ""
});

export const Roles = [
	{ label: "AT Super Admin", value: "super_admin" },
	{ label: "AT Admin", value: "admin" },
	{ label: "AT Manager", value: "manager" },
	{ label: "AT User", value: "user" }
];

export const DEF_TABLE_COLUMN = ["id", "serial", "p_serial_no", "sp_time", "prefix", "journal_id"];

export const DEF_STATIC_SENSORS = Object.freeze({
	map: [
		"gnss",
		"wifi",
		"cell"
	],
	graph: [
		// "temp",
		// "pressure",
		// "humid",
		"psu_temp_int",
		"psu_temp_ext",
		"main_shocks",
		"left_shocks",
		"right_shocks",
		"li_ion_level",
		"li_ion_temp",
		"li_ion_current",
		"li_ion_voltage",
		"li_ion_capacity",
		"battery_lvl",
		"battery_temp",
		"battery_current",
		"battery_voltage",
		"battery_capacity"
	],
	bargraph: [
		"psu_mobo",
		"psu_mains",
		"psu_intr",
		"psu_warn",
		"dc_in",
		"switch_10g",
		"router_wifi_lte",
		"mb_node_a",
		"mb_node_b"
	]
});


export const generateMOCK = (random = false) => {
	const payload = []
	for (const key in DEF_STATIC_SENSORS) {
		for (const sensor of DEF_STATIC_SENSORS[key]) {
			const add = Math.round(Math.random());
			if(random && add === 1) continue;
			payload.push({
				name: sensor,
				tablename: "shocks",
				category: key,
				desc: {},
				custom_filter: false,
				filter_path: null,
				static: true,
			})
		}
	}
	return payload;
}

export const MOCK_SENSORS = [
	...generateMOCK()
]

export const generateStaticSensors = (category) => {
	const payload = []
	for (const sensor of DEF_STATIC_SENSORS[category]) {
		payload.push({
			name: sensor,
			tablename: null,
			category,
			desc: {},
			custom_filter: false,
			filter_path: null,
			static: true,
		})
	}
	return payload;
}
