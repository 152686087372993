import React from "react";

/* MUI */
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker as MUIDatePicker } from "@mui/x-date-pickers/DateTimePicker";

const InputDateTime = (props) => {
	const {
		disabled = false,
		name,
		placeholder,
		label,
		helperText,
		formik,
		className,
		variant = "outlined",
		...rest
	} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<MUIDatePicker
				{...rest}
				disabled={disabled}
				label={label}
				inputVariant={variant}
				value={new Date(formik.values[name])}
				onChange={(value) => formik.setFieldValue(name, value)}
				size="small"
				format="dd.MM.yyyy HH:mm:ss"
				onError={(err) => {
					console.log(err)
					if (err) {
						formik.setFieldError(name, err);
					}
				}}
				renderInput={(params) => (
					<TextField error={formik.touched[name] && formik.errors[name]} size="small" fullWidth {...params} />
				)}
			/>
		</LocalizationProvider>
	);
};

export default InputDateTime;
