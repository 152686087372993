import {createSlice} from "@reduxjs/toolkit";
import { getFromStorage } from "general/storage";
import moment from "moment-timezone";

const initialState = {
    isZoom: false,
    isPan: false,
    min: Date.parse(new Date (new Date() - (3_600_000 * getFromStorage("tracker.range", 3)))),
    max: Date.parse(new Date()),
	viewportMinimum: Date.parse(new Date (new Date() - (3_600_000 * getFromStorage("tracker.range", 3)))),
	viewportMaximum: Date.parse(new Date()),
    timezone: moment.tz.guess(),
    currentGraphTimestamp: null,
    liveUpdate: false,
    allSensors: [],
    selectedSensors: [],
    loadings: {
        graph: false,
        bargraph: false,
        map: false,
    },
	tmsp: null
}

const graphsSlice = createSlice({
  name: '/dgp/graphs',
  initialState,
  reducers: {
    setZoomState(state, action){
        state.isZoom = action.payload
        if(!action.payload){
            state.min = initialState.min;
            state.max = initialState.max;
        }
    },
    toggleLoading(state, action){
        const {category = "all", status = false} = action.payload;
        if(category === "all"){
            state.loadings.bargraph = status;
            state.loadings.graph = status;
            state.loadings.map = status;
        } else {
            state.loadings[category] = status
        }
    },
    setPanState(state, action){
        state.isPan = action.payload
    },
    setSensors(state, action){
        state.allSensors = action.payload.map(sensor => {
            if(sensor?.data && sensor.data.length){
                return sensor
            }
                return {...sensor, data: []}

        })
        if(!state.selectedSensors.length){
            // const line = action.payload.find(sensor => sensor.category === "graph")
            // const bar = action.payload.find(sensor => sensor.category === "bargraph")
            // state.selectedSensors = action.payload.map(s => `${s.category}:${s.name}`)
        }
    },
    setSelectedSensors(state, action) {
        state.selectedSensors = action.payload
    },
    addSensorToSelected(state, action){
        state.selectedSensors.push(action.payload)
    },
    unSelectSensor(state, action){
        state.selectedSensors = state.selectedSensors.filter(sensor => sensor !== action.payload)
    },
    setRange(state, action){
        const {min, max} = action.payload;
        state.min = new Date(min).getTime()
        state.max = new Date(max).getTime()
    },
	setViewport(state, action){
        const {min, max} = action.payload;
        state.viewportMinimum = new Date(min).getTime()
        state.viewportMaximum = new Date(max).getTime()
    },

    setTimezone(state, action){
        state.timezone = action.payload;
        state.min = moment(state.min).tz(action.payload).valueOf();
        state.max = moment(state.max).tz(action.payload).valueOf();
    },
    setCurrentGraphTimestamp(state, action){
        state.currentGraphTimestamp = action.payload
    },
    setData(state, action){
        const {source, data} = action.payload;
        state.allSensors = state.allSensors.map( sensor => {
            if(sensor.source === source){
                return {
                    ...sensor, data
                }
            }
                return sensor

        })
    },
	setTmsp(state, action){
		state.tmsp = action.payload
	},
    setLiveUpdate(state,action){
        state.liveUpdate = action.payload
    }
  },
})

export const {
    setZoomState,
    setPanState,
    setSensors,
    addSensorToSelected,
    unSelectSensor,
    setRange,
    setTimezone,
    setCurrentGraphTimestamp,
    toggleLoading,
    setData,
    setSelectedSensors,
	setTmsp,
    setLiveUpdate,
	setViewport
} = graphsSlice.actions


export default graphsSlice.reducer;
