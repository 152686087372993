import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const parsersAPI = createApi({
	reducerPath: "parsers/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("parsers")),
	keepUnusedDataFor: 30,
	endpoints: build => ({
		findParsers: build.query({
			query: params => ({
				url: "find",
				params
			}),
			transformResponse: response => response.payload,
		}),
	})
});

export const {
	useFindParsersQuery,
    useLazyFindParsersQuery
} = parsersAPI;

export default parsersAPI;