import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	device: null,
	messages: [],
	loading: false,
	initiated: false,
	latitudes: null,
	longitudes: null,
	corruption: 5
}

const trackerDataSlice = createSlice({
	name: '/dgp/trackerData',
	initialState,
	reducers: {
		initiate(state){
			state.initiated = true
		},
		setDevice(state, action){
			state.device = action.payload
		},
		toggleLoading(state, action){
			state.loading = action.payload
		},
		setMessages(state, action){
			state.messages = action.payload
		},
		setLatitudes(state, action){
			state.latitudes = action.payload
		},
		setLongitudes(state, action){
			state.longitudes = action.payload
		},
		setCorruption(state, action){
			state.corruption = action.payload
		}
	},
})

export const {
	setDevice,
	toggleLoading,
	setMessages,
	initiate,
	setLatitudes,
	setLongitudes,
	setCorruption
} = trackerDataSlice.actions

export default trackerDataSlice.reducer;