import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const servicesAPI = createApi({
	reducerPath: "services/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("services")),
	keepUnusedDataFor: 30,
	endpoints: build => ({
		findMonitoring: build.query({
			query: params => ({
				url: "monitoring",
				params
			}),
			transformResponse: response => response.payload,
		}),
		findStatistics: build.query({
			query: params => ({
				url: "statistics",
				params
			}),
			transformResponse: response => response.payload,
		}),
	})
});

export const {
	useFindMonitoringQuery,
    useLazyFindMonitoringQuery,
	useFindStatisticsQuery,
	useLazyFindStatisticsQuery
} = servicesAPI;

export default servicesAPI;