import React from "react";

/* MUI */
import { Typography, Checkbox, FormControlLabel, Box } from "@mui/material";

const InputCheckBox = ({ formik, name, label, disabled = false, onChange = () => {} }) => (
		<Box>
			<FormControlLabel
				control={
					<Checkbox
						disabled={disabled}
						name={name}
						checked={formik.values[name]}
						onChange={e => {
							onChange(formik, e);
							formik.handleChange(e);
						}}
					/>
				}
				label={<Typography sx={{pr: 1}}>{label}</Typography>}
				labelPlacement="start"
			/>
		</Box>
	);

export default InputCheckBox;
