import React, { createContext, useState, useEffect } from "react";
import { useDebounce } from "../../../general/hooks";

export const tableContext = createContext({});

const DEF_PAGE_SIZE = 5;

const SelectableTableProvider = props => {
	const {
		useHook = () => {},
		children,
		hookProps = {},
		hookOptions = {},
		sx,
		initSort,
		onPageChange = () => {},
		onPageSizeChange = () => {},
		initOffset = 0,
		customPageSize,
		initLimit = 5,
		useRefreshButton = false,
		onLoading = () => {},
		disabled = false,
	} = props;

	const [offset, setOffset] = useState(initOffset);
	const [limit, setLimit] = useState(customPageSize || initLimit || DEF_PAGE_SIZE);
	const [search, setSearch] = useState("");
	const [sortDir, setsortDir] = useState("desc");
	const [sortItem, setSortedItem] = useState(initSort);
	const debounce = useDebounce(search);

	const { data, isFetching, refetch } = useHook({
		offset,
		limit,
		search: debounce,
		sortDir,
		sortItem,
		...hookProps
	}, {...hookOptions});

	useEffect(() => {
		if(isFetching) {
			onLoading()
		}
	}, [isFetching])

	const handlePageChange = (der) => {
		if(der === "next") {
			setOffset(offset + limit)
			onPageChange((offset + limit))
		}
		else {
			setOffset(offset - limit)
			onPageChange((offset - limit))
		}
	}

	const handlePageSizeChange = lim => {
		setLimit(lim);
		setOffset(0)
		onPageSizeChange(lim);
	};

	const handleSort = opt => {
		setSortedItem(opt.item);
		setsortDir(opt.der);
	};

	const handleRefresh = () => {
		refetch()
	}

	const handleSearch = value => (value && value !== "" ? setSearch(value) : setSearch(""));

	return (
		<tableContext.Provider
			sx={sx}
			value={{
				rows: data?.items || [],
				total: data?.total || 0,
				loading: isFetching,
				pageSize: customPageSize || limit,
				disabled: isFetching || disabled,
				totalPages: data?.total ? Math.ceil(data.total / limit) : 0,
				currentPage: parseInt(offset / limit + 1, 10),
				onPageSizeChange: handlePageSizeChange,
				nextPage: handlePageChange,
				prevPage: handlePageChange,
				onSearch: handleSearch,
				onSort: handleSort,
				perPage: [5, 10, 20, 50],
				allowPageSizeChange: data?.total > 5 && !customPageSize,
				sort: { der: sortDir, item: sortItem },
				useRefreshBtn: useRefreshButton,
				onRefresh: handleRefresh
			}}
		>
			{children}
		</tableContext.Provider>
	);
};

export default SelectableTableProvider;
