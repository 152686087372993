import React, { useState } from "react";

/* MUI */
import { Typography, Box } from "@mui/material";

/* Components */
import SmallTextField from "../SmallTextField";

const SearchLine = props => {
	const { onChange, found, label, disabled, foundLabel } = props;
	const [value, setValue] = useState("");

	const onInputChange = e => {
		onChange(e);
		setValue(e.target.value);
	};

	return (
		<Box gap={1} sx={{display: "flex", flexDirection: "row", alignItems: "center", mb: 1}}>
			<SmallTextField
				type="search"
				disabled={disabled}
				value={value}
				label={label}
				onChange={onInputChange}
				placeholder={label}
			/>
			<Box sx={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
			<Typography fontWeight={500} fontSize="14px" sx={{color: "text.secondary", textAlign: "center"}}>
				{foundLabel}
			</Typography>
			<Typography fontWeight={700} fontSize="14px" sx={{color: found > 0 ? "primary.main" : "text.secondary", textAlign: "center"}}>
				{found}
			</Typography>
			</Box>
		</Box>
	);
};

export default SearchLine;
