import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const integratedDevicesAPI = createApi({
	reducerPath: "integratedDevices/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("integrated_devices")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindDevices"],
	endpoints: build => ({
		findIntegratedDevices: build.query({
			query: params => ({
				url: "find",
				params
			}),
			transformResponse: response => response.payload,
			providesTags: ["FindDevices"]
		}),
		addIntegratedDevice: build.mutation({
			query: data => ({
                url: "add",
                method: "POST",
                body: data
            }),
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			}),
			invalidatesTags: ["FindDevices"]
		}),
		editIntegratedDevice: build.mutation({
			query: data => ({
                url: "save",
                method: "PATCH",
                body: data
            }),
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			}),
			invalidatesTags: ["FindDevices"]
		}),
		removeIntegratedDevice: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindDevices"]
		}),

	})
});

export const {
	useFindIntegratedDevicesQuery,
    useLazyFindIntegratedDevicesQuery,
    useEditIntegratedDeviceMutation,
    useAddIntegratedDeviceMutation,
    useRemoveIntegratedDeviceMutation
} = integratedDevicesAPI;

export default integratedDevicesAPI;