import React, { useEffect, useState, useRef, useCallback } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useStateWithPromise = (initialState) => {
	const [state, setState] = useState(initialState);
	const resolverRef = useRef(null);

	useEffect(() => {
		if (resolverRef.current) {
			resolverRef.current(state);
			resolverRef.current = null;
		}
		/**
		 * Since a state update could be triggered with the exact same state again,
		 * it's not enough to specify state as the only dependency of this useEffect.
		 * That's why resolverRef.current is also a dependency, because it will guarantee,
		 * that handleSetState was called in previous render
		 */
	}, [resolverRef.current, state]);

	const handleSetState = useCallback(
		(stateAction) => {
			setState(stateAction);
			return new Promise((resolve) => {
				resolverRef.current = resolve;
			});
		},
		[setState]
	);

	return [state, handleSetState];
};

const FormikForm = (props) => {
	const {
		formik: formikInstance,
		validationSchema,
		initialValues,
		children,
		onSubmit,
		data = {},
		enableReinitialize = false,
		formClassName = "",
		/* Events */
		onClear = () => {},
	} = props;

	const [setLoading] = useStateWithPromise(false);
	const { t } = useTranslation("main");

	const formik =
		formikInstance ||
		useFormik({
			validationSchema,
			initialValues,
			onSubmit,
			enableReinitialize,
		});

	const preSubmit = () => {
		for (const key in formik.errors) {
			formik.setFieldTouched(key, true);
		}
		if (Object.keys(formik.errors).length >= 1) {
			toast.error(t("defaults.form.errors.fill_form"));
		}
	};

	const clear = () => {
		onClear();
	};

	/*
	 * We can use "enableReinitialize" and pass new async data
	 * through "initialValues", but in this case user will see just empty form
	 * for a few secs. and we don`t have ability to handle "Loading",
	 * that`s why we use custom async useStateWithPromise() and setting all values manually
	 */
	const setData = async (formData) => {
		if (Object.keys(formData).length) {
			await setLoading(true);
			await setLoading(false);
		}
	};

	useEffect(() => {
		if (data) setData(data);
	}, [data]);

	useEffect(() => () => formik.resetForm(), []);

	return (
		<form onSubmit={formik.handleSubmit} className={`form ${formClassName}`} style={{ width: "100%" }}>
			{formikInstance ? children : children(formik, preSubmit, clear, formik.handleSubmit)}
		</form>
	);
};

export default FormikForm;
