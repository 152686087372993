import React from "react";
import { asyncComponent } from "react-async-component";
import Loader from "@components/shared/ui/Loader";
import { Box } from "@mui/material";

const Pages = {};

const PathToPagesComponents = [
	"./HomePage",
	"./LoginPage",
	"./DashboardPage",
	"./ResetPasswordPage",
	"./WelcomeNewUserPage",
	"./RequestPasswordChangePage",
	"./FAQPage",
	"./TrackerPage",
	"./TrackerPageGhost",
	"./AdminPage",
	"./SuperAdminPage",
	"./ManagerPage"
];

for (const path of PathToPagesComponents) {
	const pageName = path.split("/")[path.split("/").length - 1];
	Pages[pageName] = asyncComponent({
		name: pageName,
		resolve: () => import(`${path}`).then(x => x.default),
		LoadingComponent: () => <Loader sx={{display: "flex", marginTop: "200px"}}/>,
		ErrorComponent: ({ errors, error }) => {
			console.log("page load error", errors, error);
			return (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: "100%",
						fontSize: "48px",
						fontWeight: 900
					}}
				>
					Page not Found or not allowed to you
				</Box>
			);
		}
	});
}

export default { ...Pages };
