import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";
import moment from "moment-timezone";
import { getRoleLabel } from "@helpers";


export const userLogsAPI = createApi({
	reducerPath: "logs/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("users/logs")),
	keepUnusedDataFor: 30,
	endpoints: build => ({
		findUserLogs: build.query({
			query: ({timezone, spaceId, ...params}) => ({
				url: "get",
				params: {
					...params,
					space_id: spaceId
				}
			}),
			transformResponse: (response, meta, {timezone = "utc"}) => ({
                total: response.payload.total,
                items: response.payload.items.map(item => ({
                    id: item.id,
					datetime: moment(item?.datetime).tz(timezone).format("Do MMM YYYY, HH:mm:ss") || null,
					email: item.userdata.email,
					role: getRoleLabel(item.userdata.role),
					action: item.action,
					space: item.space_info.name
                }))
            }),
		}),
		findUserLogsBySpace: build.query({
			query: ({spaceId, timezone, ...params}) => ({
				url: `get/space/${spaceId}`,
				params: {
					...params,
					space_id: spaceId
				}
			}),
			transformResponse: (response, meta, {timezone = "utc"}) => ({
				total: response.payload.total,
                items: response.payload.items.map(item => ({
                    id: item.id,
					datetime: moment(item?.datetime).tz(timezone).format("Do MMM YYYY, HH:mm:ss") || null,
					email: item.userdata.email,
					role: getRoleLabel(item.userdata.role),
					action: item.action,
					space: item.space_info.name,
					group: item.groups
                }))
            }),
		}),
		findUserLogsByGroup: build.query({
			query: ({spaceId, timezone, ...params}) => ({
				url: `get/group/${spaceId}`,
				params
			}),
			transformResponse: (response, meta, {timezone}) => ({
				total: response.payload.total,
                items: response.payload.items.map(item => ({
                    id: item.id,
					datetime: moment(item?.datetime).tz(timezone).format("Do MMM YYYY, HH:mm:ss") || null,
					email: item.email,
					role: getRoleLabel(item.role),
					action: item.action,
					space: item.space_info.name,
					group: item.groups
                }))
            }),
		})
	})
});

export const {
	useFindUserLogsQuery,
	useLazyFindUserLogsQuery,
	useFindUserLogsBySpaceQuery,
	useLazyFindUserLogsBySpaceQuery,
	useFindUserLogsByGroupQuery,
	useLazyFindUserLogsByGroupQuery
} = userLogsAPI;

export default userLogsAPI;
