import React from "react";

/* MUI */
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";

const InputSelect = (props) => {
	const {
		disabled,
		formik,
		label,
		items = [],
		name,
		value,
		valueFormatter = (val) => val, 
		onChange = () => {},
		size = "small",
	} = props;

	return (
		<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
			<Box sx={{ width: "100%" }}>
				<FormControl size="small" variant="outlined" sx={{ width: "100%" }} disabled={disabled}>
					<InputLabel id={`dgp-mui-select-${label}-label`}>{label}</InputLabel>
					<Select
						name={name}
						size={size}
						labelId={`dgp-mui-select-${label}-label`}
						id={`dgp-mui-select-${label}`}
						value={value ? valueFormatter(value) : valueFormatter(formik.values[name])}
						sx={{ width: "100%" }}
						onChange={(e) => {
							formik.handleChange(e);
							onChange(formik, e);
						}}
						onBlur={formik.handleBlur}
						label={label}
						autoWidth
						helperText={formik.touched[name] && formik.errors[name]}
						error={formik.touched[name] && formik.errors[name]}
					>
						{items.map((item) => (
							<MenuItem value={item.value} disabled={item.disabled} sx={{ width: "100%" }}>
								{item.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</Box>
	);
};

export default InputSelect;
