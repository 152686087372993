import * as err from "../../../constants/errors"

const generateContent = (type) => {
    switch (type) {
        case err.SPACE_CONT_INVOICES:
            return {
                hasList: false,
                title: "Error on Remove Space!",
                text: "You cannot remove a Space as it contains invoices!",
                sub: 'Please go to "Billing" and remove all invoices from it.'
            }
        case err.SPACE_CONT_DEVICES:
            return {
                hasList: true,
                title: "Error on Remove Space!",
                text: "You cannot remove a Space as it contains devices:",
                sub: 'Please go to "Devices/Spaces" and remove all devices from it.'
            }
        case err.GROUP_CONT_DEVICES:
            return {
                hasList: true,
                title: "Error on Remove Group!",
                text: "You cannot remove a group as it contains devices:",
                sub: 'Please go to "Devices/Groups" and remove all devices from it.'
            }
        case err.GROUP_CONT_USERS:
            return {
                hasList: true,
                title: "Error on Remove Group!",
                text: "You cannot remove a group as it contains users:",
                sub: 'Please go to "Users/Groups" and remove all users from it.'
            }
        case err.SPACE_CONT_USERS:
            return {
                hasList: true,
                title: "Error on Remove Space!",
                text: "You cannot remove a space as it contains users:",
                sub: 'Please go to "Users" and remove all users in space or change space!'
            }
        case err.SPACE_CONT_GROUPS:
            return {
                hasList: true,
                title: "Error on Remove Space!",
                text: "You cannot remove a space as it contains groups:",
                sub: 'Please go to "Spaces/Groups" and remove all groups in space or change space!'
            }
        case "USER_IN_GROUP": {
            return {
                hasList: true,
                title: "Error on Remove User!",
                text: "You cannot remove a user as it assigned to groups:",
                sub: 'Please go to "Users/Groups" and remove this user from all groups!'
            }
        }
        case err.MODEL_CONT_DEVICES: {
            return {
                hasList: true,
                title: "Error on Remove Model!",
                text: "You cannot remove a model as it contains to devices:",
                sub: 'Please go to "Devices/Keys" and remove/edit all devices with this model!'
            }
        }
        case err.TABLE_BELOGS_TO_SENSORS: {
            return {
                hasList: true,
                title: "Error on Remove Table",
                text: "You cannot remove a table as it assigned to sensors:",
                sub: 'Please go to "Sensors" and remove/edit all sensors with this table!'
            }
        }
        case err.SKU_BELOGS_TO_DEVICES: {
            return {
                hasList: true,
                title: "Error on Remove SKU",
                text: "You cannot remove a SKU as it assigned to devices:",
                sub: 'Please go to "Devices/Keys" and remove/edit all devices with this SKU!'
            }
        }
        case err.SUBSYSTEM_BELONGS_TO_INTEGRATED_DEVICES: {
            return {
                hasList: true,
                title: "Error on Remove Subsystem group",
                text: "You cannot remove a Subsystem group as it assigned to intagrated devices:",
                sub: 'Please go to "Models/SKU" and remove/edit all intagrated devices with this Subsystem!'
            }
        }
        case err.INTEGRATED_DEVICE_BELOGS_TO_SKU: {
            return {
                hasList: true,
                title: "Error on Remove Integrated Device",
                text: "You cannot remove a Integrated Device as it assigned to SKU:",
                sub: 'Please go to "Models/SKU" and remove/edit all SKU with this Integrated Device!'
            }
        }
        default:
            return {
                hasList: false,
                title: "",
                text: "",
                sub: ''
            }
    }
}

export default generateContent;