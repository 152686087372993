import React,{useState} from "react";

const FileSelector = ({
    children,
    accept = [".png", ".svg"],
	onLoaded = () => {}
}) => {

    const id = `file-upload-${Math.random()}`
    const [file, setFile] = useState(null);

    const onClick = () => {
        const input = document.getElementById(id);
        input.click();
    }

    const validateFileName = (files) => {
        let localFile = files;
        const fileName = localFile.name.replace(/ |-/g, '_');
        localFile = new File([localFile], fileName, {type: localFile.type});
        return localFile;
    }

    const clear = (e) =>{
        setFile(null)
        e.target.value = null //eslint-disable-line
    }

    const onUpload = async (newFile, e) => {
        if(!file){
            const f = validateFileName(newFile)
            onLoaded(f);
            setFile(f)
        }
        clear(e)
    }

    return(
        <>
        <input
            id={id}
            key={id}
            type="file"
            style={{ display: "none" }}
            accept={accept.join(", ")}
            onChange={(e) => onUpload(e.target.files[0], e)}
            onBlur={(e) => onUpload(e.target.files[0], e)}
            />
            {children(onClick, file, clear)}
        </>
    );
}

export default FileSelector;
