import React from "react";

/* MUI */
import { Box } from "@mui/material";

/* Util. */
import { getBackEndUrl } from "general/helpers";


const SpaceLogo = props => {
	const { logo, onClick = false, logoWidth = "255px", logoHeight = "75px", logoSize = "contain" } = props;

	return (
		<Box
			onClick={onClick || (() => {})}
			sx={{
				cursor: onClick ? "pointer" : "auto",
				width: logoWidth,
				height: logoHeight,
				maxWidth: logoWidth,
				maxHeight: logoHeight
			}}
		>
			{!logo ? (
				<Box
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: "background.border",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					No Logo Selected
				</Box>
			) : (
				<Box
					sx={{
						backgroundImage: `url(${getBackEndUrl(logo.url)})`,
						backgroundSize: logoSize,
						backgroundRepeat: "no-repeat",
						width: "100%",
						height: "100%",
						backgroundPosition: "center"
					}}
				/>
			)}
		</Box>
	);
};

export default SpaceLogo;
