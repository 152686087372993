import React from "react";

import { Radio, RadioGroup, Typography, FormControlLabel, FormControl } from "@mui/material";

const InputRadio = ({ formik, disabled, name, items = [] }) => (
		<FormControl component="div" disabled={disabled} sx={{ width: "100%", py: 1 }}>
			<RadioGroup aria-label={name} name={name} value={formik.values[name]} onChange={formik.handleChange} sx={{display: "flex", flexDirection: "row", alignSelf: "flex-end"}}>
				{items.map(item => (
					<FormControlLabel
						label={<Typography sx={{ pr: 1 }}>{item.label}</Typography>}
						value={item.value}
						control={<Radio />}
						labelPlacement="start"
					/>
				))}
			</RadioGroup>
		</FormControl>
	);

export default InputRadio;
