import React from "react";

import { CircularProgress, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import classnames from "classnames";

const useStyles = makeStyles(() => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
		backgroundColor: "rgba(161, 161, 161, 0.1)"
	},
	progress: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: "auto"
	}
}));

const Loader = props => {
	const classes = useStyles();
	const { style = {}, className, sx = {}} = props;
	return (
		<Box className={classnames(classes.root, className && className)} sx={{ ...style, ...sx }}>
			<CircularProgress className={classes.progress} />
		</Box>
	);
};

export default Loader;
