import { createSlice } from "@reduxjs/toolkit";
import { getFromStorage, setToStorage } from "general/storage";

/* Initial State & State structure */
const initialState = {
	drawer: {
		expanded: getFromStorage("tracker.drawer.expanded", false),
	},
	model: {
		expanded: getFromStorage("tracker.model.expanded", true),
	},
	deviceStatus: {
		expanded: getFromStorage("tracker.deviceStatus.expanded", true),
	},
	deviceList: {
		selected: getFromStorage("tracker.deviceList.selected", null),
		expanded: getFromStorage("tracker.deviceList.expanded", true),
		offset: getFromStorage("tracker.deviceList.offset", 0),
		pageSize: getFromStorage("tracker.deviceList.pageSize", 5),
	},
	map: {
		expanded: getFromStorage("dashboard.map.expanded", true),
	},
	alertList: {
		expanded: getFromStorage("dashboard.alertList.expanded", true),
		range: getFromStorage("dashboard.alertList.range", 3),
	},
};

const trackerSlice = createSlice({
	name: "tracker",
	initialState,
	reducers: {
		/* Drawer */
		setMobileDrawer(state, action) {
			state.drawer.expanded = action.payload;
			setToStorage("dashboard.drawer.expanded", action.payload);
			if (!action.payload) {
				state.model.expanded = action.payload;
				state.deviceStatus.expanded = action.payload;
				state.deviceList.expanded = action.payload;
			} else {
				state.model.expanded = getFromStorage("tracker.model.expanded", false);
				state.deviceStatus.expanded = getFromStorage("tracker.deviceStatus.expanded", false);
				state.deviceList.expanded = getFromStorage("tracker.deviceList.expanded", false);
			}
		},
		/* Model */
		setModelExpanded(state, action) {
			state.model.expanded = action.payload;
			setToStorage("tracker.model.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("tracker.drawer.expanded", action.payload);
			}
		},

		/* Device Status */
		setDeviceStatusExpanded(state, action) {
			state.deviceStatus.expanded = action.payload;
			setToStorage("tracker.deviceStatus.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("tracker.drawer.expanded", action.payload);
			}
		},

		/* Device */
		setSelectedDeviceId(state, action) {
			state.deviceList.selected = action.payload;
			setToStorage("tracker.deviceList.selected", action.payload);
		},
		setDeviceListExpanded(state, action) {
			state.deviceList.expanded = action.payload;
			setToStorage("tracker.deviceList.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("tracker.drawer.expanded", action.payload);
			}
		},
		setDeviceListOffset(state, action) {
			state.deviceList.offset = action.payload;
			setToStorage("tracker.deviceList.offset", action.payload);
		},
		setDeviceListPageSize(state, action) {
			state.deviceList.pageSize = action.payload;
			setToStorage("tracker.deviceList.pageSize", action.payload);
		},

		/* Map */
		setMapExpanded(state, action) {
			state.map.expanded = action.payload;
			setToStorage("tracker.map.expanded", action.payload);
		},

		/* Alert */
		setAlertListExpanded(state, action) {
			state.alertList.expanded = action.payload;
			setToStorage("tracker.alertList.expanded", action.payload);
		},
		setAlertListRange(state, action) {
			state.alertList.range = action.payload;
			setToStorage("tracker.alertList.range", action.payload);
		},
	},
});

export const {
	setMobileDrawer,
	setModelExpanded,
	setDeviceStatusExpanded,
	setSelectedDeviceId,
	setDeviceListExpanded,
	setDeviceListOffset,
	setDeviceListPageSize,
	setMapExpanded,
	setAlertListExpanded,
	setAlertListRange,
} = trackerSlice.actions;

export default trackerSlice.reducer;
