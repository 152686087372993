import React from "react";

/* Material UI */
import { Box, Typography } from "@mui/material";

/* Util. */
import classnames from "classnames";

const LabeledBox = props => {
	const { label = "", style, className, children, contentClassName, sx, contentSx } = props;
	return (
		<Box sx={{ width: "100%", height: "100%", mt: 2.5, ...sx, ...style }} className={classnames(className && className)}>
			<Box
				sx={{
					display: "flex",
                    position: "relative",
                    height: "100%",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					border: "2px solid",
                    borderColor: "primary.light",
					py: 3,
					backgroundColor: "background.paper",
                    borderRadius: "10px",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						p: "5px 10px",
						borderRadius: "10px",
						border: "2px solid",
                        borderColor: "primary.light",
						top: 0,
						left: 20,
						transform: "translateY(-50%)",
						backgroundColor: "background.paper",
						minWidth: "150px",
						maxHeight: "35px"
					}}
				>
					<Typography fontWeight={500} sx={{fontSize: "18px", lineHeight: "20px"}}>{label}</Typography>
				</Box>
				<Box sx={{ width: "100%", ...contentSx }} className={classnames(contentClassName && contentClassName)}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default LabeledBox;
