import React, { useMemo, useRef, useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import { CSVLink } from "react-csv";
import PrintComponent from "./PrintComponent";
import GetAppIcon from "@mui/icons-material/GetApp";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";

const Csvs = (props) => {
	const { columns, rows } = props;

	const printComponentRef = useRef(null);

	const MemorizedIcon = useCallback(
		() => (
			<IconButton>
				<PrintIcon />
			</IconButton>
		),
		[]
	);

	const csvData = useMemo(() => {
		const data = [];
		for (const row of rows) {
			const temp = { ...row };
			delete temp.id;
			data.push(temp);
		}
		return data;
	}, [rows]);

	const csvHeaders = useMemo(() => {
		const headers = [];
		for (const key in columns) {
			headers.push({ label: columns[key].title, key });
		}
		return headers;
	}, [columns]);

	return (
		<Box
			sx={{
				ml: "auto",
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-start",
				justifyContent: "flex-end",
			}}
		>
			{csvData.length <= 0 ? (
				<IconButton>
					<GetAppIcon />
				</IconButton>
			) : (
				<CSVLink data={csvData} headers={csvHeaders} filename={`${new Date().toISOString()}-user-logs.csv`}>
					<IconButton>
						<GetAppIcon />
					</IconButton>
				</CSVLink>
			)}
			<ReactToPrint trigger={MemorizedIcon} content={() => printComponentRef.current} />
			<Box sx={{ display: "none" }}>
				<PrintComponent ref={printComponentRef} data={rows} />
			</Box>
		</Box>
	);
};

export default Csvs;
