/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const unsaved = [];

const initialState = {
    original: {},
	fields: [],
	unsaved,
	toSave: {},
};

const sourceSlice = createSlice({
	name: "/dgp/deviceSKU/source",
	initialState,
	reducers: {
		clear(state) {
			state = initialState;
		},
		setSource(state, action) {
			const { payload } = action;
			state.original = payload;
			state.toSave = payload;
			const { fields } = payload;
			state.fields = fields.map((f, i) => ({...f, seq: i, uid: uuidv4(),}));
		},
		onChange(state, action) {
			const { field, data } = action.payload;
			if (field in state) {
				state[field] = data;
			}
			if (!state.unsaved.includes(field)) {
				state.unsaved = [...state.unsaved, field];
			}
		}
	},
});

export const { actions } = sourceSlice;
export default sourceSlice.reducer;
