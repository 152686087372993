import React,{useRef, useState} from "react";

/* MUI */
import {Box, Popover, Typography, TextField, IconButton, List, ListItem} from "@mui/material"
import InfoIcon from "@mui/icons-material/Info";
import RefreshIcon from "@mui/icons-material/Refresh";


const InputTextareaWithHint = (props) => {
    const {
        disabled,
        formik,
        label,
        name,
        placeholder = "Message",
        rows = 5,
        elements = {
            firstname: false,
            lastname: false,
            space_name: false,
            group_name: false
        },
        disableHint = false,
        reset = null
    } = props;

    const btnRef = useRef(null);
    const [isOpen, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const openHint = () => {
        setOpen(true)
        setAnchorEl(btnRef.current)
    }

    const closeHint = () => {
        setOpen(false)
        setAnchorEl(null)

    }

    const resetToDef = () => {
        formik.setFieldValue(name, reset)
    }

    return(
        <Box sx={{width: "100%", display: "flex", flexDirection: "row", flexWrap: "nowrap"}} ref={btnRef}>
            <TextField
                disabled={disabled}
                multiline
                rows={rows}
                sx={{width: "100%"}}
                label={label}
                name={name}
                value={formik.values[name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={placeholder}
                error={formik.touched[name] && formik.errors[name]}
                helperText={formik.errors[name]}
                variant="outlined"
            />
            { !disableHint && <>
                <Box>
                    <IconButton disabled={disabled} color="primary" onClick={openHint} aria-label="upload picture" component="span">
                            <InfoIcon />
                    </IconButton>
                    {
                        reset && 
                        <IconButton disabled={disabled} color="primary" onClick={resetToDef} aria-label="upload picture" component="span">
                                <RefreshIcon />
                        </IconButton> 
                    }
                </Box>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={closeHint}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                <Box sx={{p: 1}}>
                    <Typography>You can use this placeholders:</Typography>
                    <List>
                        {elements.firstname && <ListItem><Typography><strong>{"{{user_firstname}}"}</strong> - Users firstname(in case of no firstname will be replaced with email)</Typography></ListItem>}
                        {elements.lastname && <ListItem><Typography><strong>{"{{user_lastname}}"}</strong> - Users lastname(in case of no lastname will be)</Typography></ListItem>}
                        {elements.space_name && <ListItem><Typography><strong>{"{{space_name}}"}</strong> - Space name of recipient</Typography></ListItem>}
                        {elements.group_name && <ListItem><Typography><strong>{"{{group_name}}"}</strong> - Group name of recipient</Typography></ListItem>}
                        <ListItem><Typography><strong>{"{{user_email}}"}</strong> - Email of recipient</Typography></ListItem>
                    </List>
                </Box>
            </Popover>
            </>}
    </Box>
    );
}

export default InputTextareaWithHint;