import React, { Suspense, useEffect, useState } from "react";

/* Components */
import AppMenu from "./components/menu/AppMenu";
import Loader from "./components/shared/ui/Loader";
import PrivateRoute from "./components/shared/routes/PrivateRoute";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import createMuiTheme from "theme";

/* Utils */
import { Switch, Route } from "react-router-dom";
import moment from "moment-timezone";

/* Redux */
import { useDispatch, useSelector } from "react-redux";
import { setTimezone } from "redux/tracker/graphs.slice";
import useAuth from "./authentication/hooks/use-auth";

/* Pages */
import Pages from "./pages";
import AppEvents from "@components/shared/app-events";

const App = () => {
	const [userCalled, setUserCalled] = useState(false);
	const [successUser, setSuccessUser] = useState(false);
	const dispatch = useDispatch();
	const auth = useAuth();
	const isLoading = useSelector((state) => state.app.isLoading);
	const mode = useSelector((state) => state.app.mode);

	const fetchMe = async () => {
		try {
			setSuccessUser(true);
		} catch (error) {
			setSuccessUser(false);
		}
		setUserCalled(true);
	};

	useEffect(() => {
		fetchMe();
	}, []);

	useEffect(() => {
		if (auth.user && userCalled && successUser) {
			if (auth.user.timezone) {
				dispatch(setTimezone(auth.user?.timezone || moment.tz.guess()));
			}
		}
	}, [auth.user, userCalled, successUser]);

	if (!userCalled) {
		return null;
	}

	return (
		<ThemeProvider theme={createMuiTheme(mode)}>
			<CssBaseline />

			<Suspense fallback={<Loader sx={{ display: "flex", marginTop: "200px" }} />}>
				<div style={{ marginTop: "65px", minHeight: "100%", position: "relative" }}>
					<AppEvents />
					{isLoading && (
						<div className="loader">
							<Loader />
						</div>
					)}
					<AppMenu user={auth.user && auth.user} />
					<Switch>
						<PrivateRoute slug="dashboard_view" exact path="/dashboard" component={Pages.DashboardPage} />
						<PrivateRoute slug="tracker_view" exact path="/tracker" component={Pages.TrackerPageGhost} />
						<PrivateRoute slug="tracker_view" exact path="/tracker/:serial" component={Pages.TrackerPage} />
						<PrivateRoute slug="superadmin_view" path="/superadmin" component={Pages.SuperAdminPage} />
						<PrivateRoute slug="admin_view" path="/admin" component={Pages.AdminPage} />
						<PrivateRoute slug="manager_view" path="/manager" component={Pages.ManagerPage} />
						<Route exact path="/login" component={Pages.LoginPage} />
						<Route exact path="/resetPassword" component={Pages.ResetPasswordPage} />
						<Route exact path="/welcome" component={Pages.WelcomNewUserPage} />
						<Route exact path="/requestPasswordReset" component={Pages.RequestPasswordChangePage} />
						<Route exact path="/faq" component={Pages.FAQPage} />
						<Route path="/" exact component={Pages.HomePage} />
					</Switch>
				</div>
			</Suspense>
		</ThemeProvider>
	);
};

export default App;
