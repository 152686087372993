import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const deviceSkuAPI = createApi({
	reducerPath: "deviceSKU/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("devices")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindSKU"],
	endpoints: build => ({
        addMessages: build.mutation({
            query: data => ({
                url: "messages",
                method: "POST",
                body: data
            })
        }),
		findSKU: build.query({
			query: params => ({
				url: "sku/find",
				params
			}),
			transformResponse: response => response.payload,
			providesTags: ["FindSKU"]
		}),
		addSKU: build.mutation({
			query: data => ({
				url: "sku/add",
				method: "POST",
				body: data
			}),
			invalidatesTags: ["FindSKU"],
			transformResponse: response => response.payload
		}),
		editSKU: build.mutation({
			query: data => ({
				url: "sku/save",
				method: "PATCH",
				body: data
			}),
			invalidatesTags: ["FindSKU"],
			transformResponse: response => response.payload
		}),
		removeSKU: build.mutation({
			query: (id) => ({
				url: `sku/remove/${id}`,
				method: "DELETE"
			}),
			invalidatesTags: ["FindSKU"],
			transformResponse: response => response.payload
		}),

	})
});

export const {
	useFindSKUQuery,
    useLazyFindSKUQuery,
    useAddMessagesMutation,
    useAddSKUMutation,
    useEditSKUMutation,
    useRemoveSKUMutation
} = deviceSkuAPI;

export default deviceSkuAPI;