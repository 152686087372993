import React, { createContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

/* Redux */
import { useSelector, useDispatch } from "react-redux";
import { initAuth, logoutUser } from "../../redux/auth/slice";
import { useLoginMutation, useLazyGetMeQuery, useLazyLogoutQuery } from "../../redux/auth/api";
import { setDefs, setLoading, setStorage } from "@redux/app/slice";
import { useHistory, useLocation } from "react-router";
import { useLazyGetSettingsQuery } from "@redux/app/api";

export const AuthContext = createContext({
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	platform: "JWT",
	isAllowed: () => false,
	isFieldAllowed: () => false,
	isFetching: true,
});

export const AuthProvider = (props) => {
	const { children, services, publicRoutes } = props;
	const state = useSelector((s) => s.auth);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const [fetchMe, { isFetching }] = useLazyGetMeQuery();
	const [fetchLoginUser] = useLoginMutation();
	const [fetchLogout] = useLazyLogoutQuery();
	const [fetchSettings] = useLazyGetSettingsQuery();

	const initServices = () => {
		for (const service of services) {
			if (typeof service === "function") {
				service();
			}
		}
	};

	const me = async () => {
		try {
			dispatch(setLoading(true));
			const meResult = await fetchMe();
			if (meResult.error) {
				if (!publicRoutes.includes(location.pathname)) {
					history.push("/");
				}
			} else {
				dispatch(
					initAuth({
						isAuthenticated: true,
						user: meResult.data,
					})
				);
				initServices();
				const { data: settings } = await fetchSettings();
				dispatch(setDefs(settings?.defs))
				dispatch(setStorage(settings))
			}
			dispatch(setLoading(false));
		} catch (error) {
			console.log(error);
			dispatch(setLoading(false));
		}
	};

	useEffect(() => {
		me();
	}, []);

	const login = async (loginData) => {
		const accessToken = await fetchLoginUser(loginData);
		if (!accessToken.error) {
			localStorage.setItem("accessToken", accessToken.data.token);
			await me();
		}
		return accessToken;
	};

	const logout = async () => {
		const result = await fetchLogout();
		if (result.isSuccess) {
			history.push("/");
			dispatch(logoutUser());
			localStorage.removeItem("accessToken");
		}
	};

	const isAllowed = (rule) => {
		const {
			user: { rules },
		} = state;
		return rules.includes(rule);
	};

	const isFieldAllowed = (rule) => {
		const {
			user: { rules },
		} = state;
		const [entity, field] = rule.split(":");
		const foundRule = rules.filter((r) => r.startsWith(`${entity}:edit_fields`));
		if (foundRule && foundRule.length) {
			const fields = foundRule[0].split(":")[2];
			return JSON.parse(fields).includes(field);
		}
		return true;
	};

	return (
		<AuthContext.Provider
			value={useMemo(
				() => ({
					...state,
					platform: "JWT",
					login,
					logout,
					isAllowed,
					isFieldAllowed,
					isFetching,
				}),
				[state]
			)}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
	services: PropTypes.arrayOf(PropTypes.func),
};

AuthProvider.defaultProps = {
	services: [],
};

export const AuthConsumer = AuthContext.Consumer;
