
import React, { useState } from "react";

/* MUI */
import { Button, Box, Typography, DialogActions } from "@mui/material";

/* Components */
import LabeledBox from "@components/common/LabeledBox/LabeledBox";

/* Util */

import { getRoleLabel } from "@helpers";
import { useTranslation } from "react-i18next";
import yup from "@validators";

import FormikForm, {
	InputText,
	InputCheckBox,
	InputTimezone,
	InputPassword,
	InputRadio,
} from "@components/common/FormikForm";


const ProfileForm = (props) => {
	const { onSavePassword, toggleEditPassword, editPassword, allowEdit, onSave, data, toggleEdit, handleClose } =
		props;

	const { t } = useTranslation("main", { keyPrefix: "userProfile" });

	const [initialData, setInitialData] = useState(data);

	const passwordInitial = {
		password: "",
		repPassword: "",
	};

	const validationSchema = yup.object({
		email: yup.string().email().required(t("form.validation_errors.email.required")),
		lastname: yup.string().required(t("form.validation_errors.lastname.required")),
		firstname: yup.string().required(t("form.validation_errors.firstname.required")),
		password: yup.string().min(8, t("form.validation_errors.password.minLength")),
		repPassword: yup.string().equalTo(yup.ref("password"), t("form.validation_errors.repPassword.noMatch")),
	});

	const passwordValidationSchema = yup.object({
		password: yup.string().min(8, t("form.validation_errors.password.minLength")),
		repPassword: yup.string().equalTo(yup.ref("password"), t("form.validation_errors.repPassword.noMatch")),
	});

	const handleToggleEditPassword = () => {
		setInitialData({
			...initialData,
			password: "",
			repPassword: "",
		});
		if (onSave) toggleEditPassword();
	};

	const handleOnSave = (d) => {
		const formData = { ...d };
		delete formData.errors;
		delete formData.clear;
		delete formData.password;
		delete formData.repPassword;
		if (onSave) {
			onSave({ ...formData });
		}
	};

	const handleOnSavePassword = (values) => {
		if (onSavePassword) onSavePassword(values.password);
	};

	return (
		<Box sx={{ p: 1, display: "flex", flexDirection: "column" }}>
			<FormikForm initialValues={initialData} onSubmit={handleOnSave} validationSchema={validationSchema}>
				{(formik) => (
					<Box sx={{ display: "flex", flexDirection: "column", mt: 1 }} gap={1}>
						<Box sx={{ display: "flex", flexDirection: "column" }} gap={2}>
							<InputText
								disabled
								formik={formik}
								name="email"
								label={t("form.email.label")}
								placeholder={t("form.email.placeholder")}
							/>
							<InputText
								disabled={!allowEdit}
								formik={formik}
								name="firstname"
								label={t("form.firstname.label")}
								placeholder={t("form.firstname.placeholder")}
							/>
							<InputText
								disabled={!allowEdit}
								formik={formik}
								name="lastname"
								label={t("form.lastname.label")}
								placeholder={t("form.lastname.placeholder")}
							/>
						</Box>
						<InputRadio
							formik={formik}
							disabled={!allowEdit}
							name="notation"
							items={[
								{ label: t("form.notation.imperial"), value: "imperial" },
								{ label: t("form.notation.metric"), value: "metric" },
							]}
						/>
						<InputTimezone
							formik={formik}
							disabled={!allowEdit}
							name="timezone"
							label={t("form.timezone.label")}
							placeholder={t("form.timezone.placeholder")}
						/>

						<Typography sx={{ fontWeight: 600, mt: 1 }}>Email Notifications: </Typography>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								opacity: allowEdit ? 1 : 0.5,
							}}
						>
							<InputCheckBox
								disabled={!allowEdit}
								formik={formik}
								name="notif_alarms"
								label={<Typography>Alarms</Typography>}
								labelPlacement="start"
							/>
							<InputCheckBox
								disabled={!allowEdit}
								formik={formik}
								name="notif_warnings"
								label={<Typography>Warnings</Typography>}
								labelPlacement="start"
							/>
							<InputCheckBox
								disabled={!allowEdit}
								formik={formik}
								name="notif_info"
								label={<Typography>Info messages</Typography>}
								labelPlacement="start"
							/>
						</Box>

						<InputText
							disabled
							formik={formik}
							name="role"
							label={t("form.role.label")}
							placeholder={t("form.role.placeholder")}
							valueFormatter={getRoleLabel}
						/>
						<Box
							gap={2}
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-evenly",
								opacity: 0.5,
							}}
						>
							<InputCheckBox formik={formik} disabled name="owner" label={t("form.role.owner")} />
							<InputCheckBox formik={formik} disabled name="disabled" label={t("form.role.disabled")} />
							<InputCheckBox formik={formik} disabled name="view_only" label={t("form.role.view_only")} />
							<InputCheckBox formik={formik} disabled name="accountant" label={t("form.role.accountant")} />
						</Box>
						<Box sx={{ display: "flex", alignSelf: "flex-end" }} gap={1}>
							<Button variant="contained" size="small" onClick={toggleEdit} color="primary">
								{allowEdit ? t("form.buttons.cancel") : t("form.buttons.edit")}
							</Button>
							<Button variant="contained" type="submit" disabled={!allowEdit} size="small" color="primary">
								{t("form.buttons.save")}
							</Button>
						</Box>
					</Box>
				)}
			</FormikForm>
			<LabeledBox label={t("form.password.label")}>
				<FormikForm
					onSubmit={handleOnSavePassword}
					initialValues={passwordInitial}
					validationSchema={passwordValidationSchema}
				>
					{(formik) => (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								gap={1}
								sx={{
									display: "flex",
									flexDirection: "column",
									opacity: !editPassword ? 0.5 : 1,
									p: 1,
								}}
							>
								<InputPassword
									formik={formik}
									disabled={!editPassword}
									name="password"
									confirmName="repPassword"
									labels={{
										password: t("form.password.label"),
										confirm: t("form.repPassword.label"),
									}}
								/>
							</Box>
							<Box gap={1} sx={{ display: "flex", flexDirection: "row", alignSelf: "flex-end", mr: 2 }}>
								<Button variant="contained" size="small" onClick={handleToggleEditPassword} color="primary">
									{editPassword ? t("form.buttons.cancel") : t("form.buttons.edit")}
								</Button>
								<Button
									variant="contained"
									size="small"
									disabled={!editPassword || !formik.isValid || !formik?.values?.password?.length}
									type="submit"
									color="primary"
								>
									{t("form.buttons.save")}
								</Button>
							</Box>
						</Box>
					)}
				</FormikForm>
			</LabeledBox>
			<DialogActions sx={{ display: "flex", alignSelf: "flex-end" }}>
				<Button
					variant="contained"
					size="small"
					onClick={() => {
						handleClose();
					}}
					color="primary"
				>
					{t("form.buttons.back")}
				</Button>
			</DialogActions>
		</Box>
	);
};

export default ProfileForm;
