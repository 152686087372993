import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomDialog = props => {
	const isDownSm = useMediaQuery((theme) => theme.breakpoints.down("sm"))
	const {t} = useTranslation("main")
	const { open, fullWidth, maxWidth = "sm", handleClose, title, onlyOk = false, children, okTitle } = props;
	if (!open) return null;
	return (
		<Dialog
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
				keepMounted
				fullScreen={isDownSm && true}
				open={open}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				onClose={() => {
					handleClose(false);
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent >
					<DialogContentText id="alert-dialog-description">{children}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{onlyOk && (
						<Button
							onClick={() => {
								handleClose(false);
							}}
							color="primary"
							autoFocus
						>
							{t("defaults.buttons.ok")}
						</Button>
					)}
					{!onlyOk && (
						<Button
							onClick={() => {
								handleClose(false);
							}}
							color="primary"
						>
							{t("defaults.buttons.cancel")}
						</Button>
					)}
					{!onlyOk && (
						<Button
							onClick={() => {
								handleClose(true);
							}}
							color="primary"
							autoFocus
						>
							{okTitle || t("defaults.buttons.ok")}
						</Button>
					)}
				</DialogActions>
			</Dialog>
	);
};

export default CustomDialog;
