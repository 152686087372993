import React from "react";

/* MUI */
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";

const InputText = ({
	disabled = false,
	size = "small",
	name,
	placeholder,
	label,
	helperText,
	formik,
	className,
	secure = false,
	secureSym = "*",
	secureLen = 32,
	variant = "outlined",
	valueFormatter = (val) => val, 
	InputProps = {},
	sx = {},
	type = "text",
	path = "/",
	prefix = null,
	...rest
}) => {


	const getValue = () => {
		if (secure) return secureSym.repeat(secureLen);
		if (type === "prefix") return formik.values[name].replace(prefix, "");
		if (valueFormatter) return valueFormatter(formik.values[name])
		return formik.values[name];
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
			<TextField
				sx={{ width: "100%", ...sx }}
				size={size}
				type={type}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={formik.touched[name] && formik.errors[name]}
				inputProps={{ disabled: secure ? true : disabled }}
				name={name}
				disabled={disabled}
				value={getValue()}
				helperText={helperText || formik.errors[name]}
				variant={variant}
				label={label}
				placeholder={placeholder}
				InputProps={
					type === "path" || type === "prefix"
						? {
								startAdornment: <InputAdornment position="start">{prefix || path}</InputAdornment>,
						  }
						: InputProps
				}
				{...rest}
			/>
		</Box>
	);
};

export default InputText;
