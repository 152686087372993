import React from "react";
import { Box, IconButton, TableCell, TableHead, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Header = (props) => {
    const {columns, sort, disabled, onSort = () => {}, mini} = props;

    const handleSort = col => {
		onSort({
			item: Object.prototype.hasOwnProperty.call(col, "sortName") ? col.sortName : col.name,
			der: sort.der === "desc" ? "asc" : "desc"
		});
	};

    return(
        <TableHead sx={{backgroundColor: "background.paper"}}>
            {
                Object.keys(columns).map(col => ({name: col, ...columns[col]})).map(col => (
                    <TableCell
						key={col.name}
						sx={[
							{
								whiteSpace: "nowrap",
								fontWeight: 500,
								color: "text.secondary",
								p: mini ? 0.5 : 1,
								lineHeight: mini && "0.9em",
								borderRight: "1px solid",
								borderRightColor: "background.border",
								position: col?.fixed ? "sticky" : "initial",
								left: col?.fixed ? "0px" : "unset",
								backgroundColor: "background.paper",
								zIndex: col?.fixed ? 2 : 1
							},
							() => ({ "&:last-of-type": { borderRight: "none" } })
						]}
					>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: "background.paper" }}>
							{typeof col.title === "string" ? 
								<Typography fontWeight={700} fontSize={mini && "10px"} color="text.secondary">
									{col.title}
								</Typography>
								: col.title
							}
							
							{sort &&
								!col.noSort &&
								(sort.der === "desc" && sort.item === (col.sortName || col.name) ? (
									<IconButton
										disabled={disabled}
										size="small"
										onClick={() => handleSort(col)}
										sx={[
											{ borderRadius: "50%" },
											() => ({ "&:hover": { backgroundColor: "transparent" } })
										]}
									>
										<ExpandLessIcon
											sx={{
												backgroundColor: sort.item === (col.sortName || col.name) ? "primary.light" : "transparent",
												borderRadius: "50%"
											}}
										/>
									</IconButton>
								) : (
									<IconButton
										disabled={disabled}
										size="small"
										onClick={() => handleSort(col)}
										sx={[
											{ borderRadius: "50%" },
											() => ({ "&:hover": { backgroundColor: "transparent" } })
										]}
									>
										<ExpandMoreIcon
											sx={{
												backgroundColor: sort.item === (col.sortName || col.name) ? "primary.light" : "transparent",
												borderRadius: "50%"
											}}
										/>
									</IconButton>
								))}
						</Box>
					</TableCell>
                ))
            }
        </TableHead>
    );
}

export default Header;