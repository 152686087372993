import React from "react";
import { TableRow, TableCell } from "@mui/material";

const VirtualItem = ({ columns, height }) => (
	<TableRow sx={{ opacity: 0, cursor: "default" }}>
		{Object.keys(columns).map((i) => (
			<TableCell
				key={i}
				sx={[
					{ height, p: 1, borderRight: "1px solid", borderRightColor: "background.border" },
					() => ({ "&:last-of-type": { borderRight: "none" } }),
				]}
			>
				empty
			</TableCell>
		))}
	</TableRow>
);

export default VirtualItem;
