import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";
import qs from "qs";

export const devicesAPI = createApi({
	reducerPath: "devices/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("devices")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindDevices", "FindUnassignedDevices"],
	endpoints: (build) => ({
		findBySerial: build.query({
			query: (serial) => {
				if (!serial) {
					throw new Error("Serial is Required");
				}
				return {
					url: `get/${serial}`,
				};
			},
			transformResponse: (response) => response.payload,
		}),
		findDataBySerial: build.query({
			query: ({ source, serial, from, to, corruption, filters }) => {
				if (!serial) {
					throw new Error("Serial is Required");
				}
				return {
					url: `data/${source.replace(/%/g,'%25').replace(/\//g, '%2F')}/${serial}`,
					params: qs.stringify({ from, to, corruption, filters }),
				};
			},
			transformResponse: (response) => response.payload,
		}),
		findDevices: build.query({
			query: ({ groupId, spaceId, ...params }) => ({
				url: "find",
				params: {
					...params,
					group_id: groupId,
					space_id: spaceId,
				},
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					space: {
						name: item?.space?.name ? item?.space?.name : "-",
					},
					group: {
						name: item?.group?.name ? item?.group?.name : "-",
					},
				})),
			}),
			providesTags: ["FindDevices"],
		}),
		findOneDevice: build.query({
			query: ({ id }) => ({
				url: `find/${id}`,
			}),
			transformResponse: (response) => response.payload,
		}),
		addDevice: build.mutation({
			query: (data) => ({
				url: "add",
				method: "POST",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindDevices"],
		}),
		removeDevice: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindDevices"],
		}),
		saveDevice: build.mutation({
			query: ({ id, ...data }) => ({
				url: `save/${id}`,
				method: "PATCH",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindDevices"],
		}),
		findKeysBySerial: build.query({
			query: ({ serial }) => ({
				url: `keys/find/${serial}`,
			}),
			transformResponse: (response) => response.payload,
		}),
		findGroupMessages: build.query({
			query: ({ groupId, ...params }) => {
				if (!groupId) {
					throw new Error("Group id required");
				}
				return {
					url: `group/${groupId}/messages`,
					params,
				};
			},
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					sp_date: item.sp_time,
					nickname: item.device.nickname ? item.device.nickname : item.device.serial,
					dgp: { ...item.device },
				})),
			}),
		}),
		findDeviceMessages: build.query({
			query: ({ serial, ...params }) => ({
				url: `/messages/${serial}`,
				params,
			}),
			transformResponse: (response) =>
				response.payload.map((item) => ({
					...item,
					sp_date: item.sp_time,
					nickname: item.device.nickname ? item.device.nickname : item.device.serial,
					dgp: { ...item.device },
				})),
		}),
		findDevicesInGroup: build.query({
			query: ({ groupId }) => {
				if (!groupId) {
					throw new Error("Group id required");
				}
				return { url: `group/${groupId}/find` };
			},
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items,
			}),
		}),

		assignDeviceToSpace: build.mutation({
			query: ({ deviceId, spaceId }) => ({
				url: `${deviceId}/assign-to/space/${spaceId}`,
				method: "PATCH",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindUnassignedDevices", "FindDevices"],
		}),

		unassignDeviceFromSpace: build.mutation({
			query: ({ deviceId, spaceId }) => ({
				url: `${deviceId}/unassign-from/space/${spaceId}`,
				method: "PATCH",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindUnassignedDevices", "FindDevices"],
		}),

		assignDeviceToGroup: build.mutation({
			query: ({ deviceId, groupId }) => ({
				url: `${deviceId}/assign-to/group/${groupId}`,
				method: "PATCH",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindUnassignedDevices", "FindDevices"],
		}),

		unassignDeviceFromGroup: build.mutation({
			query: ({ deviceId, groupId }) => ({
				url: `${deviceId}/unassign-from/group/${groupId}`,
				method: "PATCH",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindUnassignedDevices", "FindDevices"],
		}),

		findUnassignedDevices: build.query({
			query: (params) => ({
				url: "unassign/find",
				params,
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["FindUnassignedDevices"],
		}),

		findUnassignedDevicesInSpace: build.query({
			query: ({ spaceId, ...params }) => ({
				url: `unassign/find/space/${spaceId}`,
				params,
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["FindUnassignedDevices"],
		}),
	}),
});

export const {
	useFindBySerialQuery,
	useLazyFindBySerialQuery,
	useFindDataBySerialQuery,
	useLazyFindDataBySerialQuery,
	useFindDevicesQuery,
	useLazyFindDevicesQuery,
	useFindOneDeviceQuery,
	useLazyFindOneDeviceQuery,
	useAddDeviceMutation,
	useRemoveDeviceMutation,
	useSaveDeviceMutation,
	useFindKeysBySerialQuery,
	useLazyFindKeysBySerialQuery,
	useFindGroupMessagesQuery,
	useLazyFindGroupMessagesQuery,
	useFindDevicesInGroupQuery,
	useLazyFindDevicesInGroupQuery,
	useFindDeviceMessagesQuery,
	useLazyFindDeviceMessagesQuery,
	useAssignDeviceToGroupMutation,
	useAssignDeviceToSpaceMutation,
	useUnassignDeviceFromGroupMutation,
	useUnassignDeviceFromSpaceMutation,
	useFindUnassignedDevicesQuery,
	useLazyFindUnassignedDevicesQuery,
	useFindUnassignedDevicesInSpaceQuery,
	useLazyFindUnassignedDevicesInSpaceQuery,
} = devicesAPI;

export default devicesAPI;
