import React from "react";

import { Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

// We can inject some CSS into the DOM.
const useStyles = makeStyles(() => ({
	button: {
		borderRadius: "30px",
		position: "relative",
		height: "36px !important",
		padding: "0 25px",
		fontSize: 16
	},
	bigger: {
		height: "56px !important",
		minWidth: "200px !important"
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	}
}));

const RoundButton = (props) => {
	const { className: classNameProp, children, type, bigger, onClick, loading, disabled, sx = {} } = props;
	const classes = useStyles();

	return (
		<Button
			variant="contained"
			color="primary"
			disabled={loading || disabled}
			onClick={onClick}
			type={type || "button"}
			sx={{ borderRadius: "30px", ...sx }}
			className={classNames(classes.button, bigger && classes.bigger, classNameProp)}
		>
			{children}
			{loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
		</Button>
	);
};

export default RoundButton
