import { createSlice } from "@reduxjs/toolkit";
import { getFromStorage, setToStorage } from "general/storage";

/* Initial State & State structure */
const initialState = {
	drawer: {
		expanded: getFromStorage("dashboard.drawer.expanded", false),
	},
	spaceList: {
		selected: getFromStorage("dashboard.spaceList.selected", null),
		expanded: getFromStorage("dashboard.spaceList.expanded", true),
		offset: getFromStorage("dashboard.spaceList.offset", 0),
		pageSize: getFromStorage("dashboard.spaceList.pageSize", 5),
	},
	groupList: {
		selected: getFromStorage("dashboard.groupList.selected", null),
		expanded: getFromStorage("dashboard.groupList.expanded", true),
		offset: getFromStorage("dashboard.groupList.offset", 0),
		pageSize: getFromStorage("dashboard.groupList.pageSize", 5),
	},
	deviceList: {
		selected: getFromStorage("dashboard.deviceList.selected", null),
		expanded: getFromStorage("dashboard.deviceList.expanded", true),
		offset: getFromStorage("dashboard.deviceList.offset", 0),
		pageSize: getFromStorage("dashboard.deviceList.pageSize", 5),
	},
	map: {
		expanded: getFromStorage("dashboard.map.expanded", true),
	},
	alertList: {
		expanded: getFromStorage("dashboard.alertList.expanded", true),
		range: getFromStorage("dashboard.alertList.range", 3),
	},
};

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setMobileDrawer(state, action) {
			state.drawer.expanded = action.payload;
			setToStorage("dashboard.drawer.expanded", action.payload);
			if (!action.payload) {
				state.spaceList.expanded = action.payload;
				state.groupList.expanded = action.payload;
				state.deviceList.expanded = action.payload;
			} else {
				state.spaceList.expanded = getFromStorage("dashboard.spaceList.expanded", false);
				state.groupList.expanded = getFromStorage("dashboard.groupList.expanded", false);
				state.deviceList.expanded = getFromStorage("dashboard.deviceList.expanded", false);
			}
		},
		/* Space */
		setSelectedSpaceId(state, action) {
			state.spaceList.selected = action.payload;
			setToStorage("dashboard.spaceList.selected", action.payload);
		},
		setSpaceListExpanded(state, action) {
			state.spaceList.expanded = action.payload;
			setToStorage("dashboard.spaceList.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("dashboard.drawer.expanded", action.payload);
			}
		},
		setSpaceListOffset(state, action) {
			state.spaceList.offset = action.payload;
			setToStorage("dashboard.spaceList.offset", action.payload);
		},
		setSpaceListPageSize(state, action) {
			state.spaceList.pageSize = action.payload;
			setToStorage("dashboard.spaceList.pageSize", action.payload);
		},

		/* Group */
		setSelectedGroupId(state, action) {
			state.groupList.selected = action.payload;
			setToStorage("dashboard.groupList.selected", action.payload);
		},
		setGroupListExpanded(state, action) {
			state.groupList.expanded = action.payload;
			setToStorage("dashboard.groupList.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("dashboard.drawer.expanded", action.payload);
			}
		},
		setGroupListOffset(state, action) {
			state.groupList.offset = action.payload;
			setToStorage("dashboard.groupList.offset", action.payload);
		},
		setGroupListPageSize(state, action) {
			state.groupList.pageSize = action.payload;
			setToStorage("dashboard.groupList.pageSize", action.payload);
		},

		/* Device */
		setSelectedDeviceId(state, action) {
			state.deviceList.selected = action.payload;
			setToStorage("dashboard.deviceList.selected", action.payload);
		},
		setDeviceListExpanded(state, action) {
			state.deviceList.expanded = action.payload;
			setToStorage("dashboard.deviceList.expanded", action.payload);
			if (action.payload && !state.drawer.expanded) {
				state.drawer.expanded = action.payload;
				setToStorage("dashboard.drawer.expanded", action.payload);
			}
		},
		setDeviceListOffset(state, action) {
			state.deviceList.offset = action.payload;
			setToStorage("dashboard.deviceList.offset", action.payload);
		},
		setDeviceListPageSize(state, action) {
			state.deviceList.pageSize = action.payload;
			setToStorage("dashboard.deviceList.pageSize", action.payload);
		},

		/* Map */
		setMapExpanded(state, action) {
			state.map.expanded = action.payload;
			setToStorage("dashboard.map.expanded", action.payload);
		},

		/* Alert */
		setAlertListExpanded(state, action) {
			state.alertList.expanded = action.payload;
			setToStorage("dashboard.alertList.expanded", action.payload);
		},
		setAlertListRange(state, action) {
			state.alertList.range = action.payload;
			setToStorage("dashboard.alertList.range", action.payload);
		},
	},
});

export const {
	setMobileDrawer,
	setSelectedSpaceId,
	setSpaceListExpanded,
	setSpaceListOffset,
	setSpaceListPageSize,
	setSelectedGroupId,
	setGroupListExpanded,
	setGroupListOffset,
	setGroupListPageSize,
	setSelectedDeviceId,
	setDeviceListExpanded,
	setDeviceListOffset,
	setDeviceListPageSize,
	setMapExpanded,
	setAlertListExpanded,
	setAlertListRange,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
