import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const billingAPI = createApi({
	reducerPath: "billing/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("billing")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindSensors", "FindSensorGroups"],
	endpoints: build => ({
        findInvoicesBySpace: build.query({
			query: ({spaceId, ...params}) => ({
				url: `${spaceId}/invoices/find`,
				params
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items.map((item) => ({
					...item,
					filename: item?.pdf.filename
				}))
			}),
			providesTags: ["FindSensors"],
        }),
		addInvoiceToSpace: build.mutation({
			query: ({spaceId, ...data}) => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `${spaceId}/invoices/add`,
					method: "POST",
					body: formData
				}
			},
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: response => response.payload
		}),
		editInvoice: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `invoices/save`,
					method: "PATCH",
					body: formData
				}
			},
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: response => response.payload
		}),
		removeInvoice: build.mutation({
			query: (id) => ({
				url: `invoice/${id}`,
				method: "DELETE"
			}),
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: response => response.payload
		}),
        payInvoice: build.mutation({
            query:({spaceId, id, data}) => ({
                url: `${spaceId}/invoice/${id}`,
                method: "POST",
                body: data
            })
        })
	})
});

export const {
	useFindInvoicesBySpaceQuery,
    useLazyFindInvoicesBySpaceQuery,
    useAddInvoiceToSpaceMutation,
    useEditInvoiceMutation,
    useRemoveInvoiceMutation,
    usePayInvoiceMutation
} = billingAPI;

export default billingAPI;