import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next  } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next )
    .init({
        fallbackLng: 'en',
        // have a common namespace used around the full app
        ns: [
            "admin_billing_page",
            "admin_dashboard_page",
            "admin_devices_groups",
            "admin_devices_keys_page",
            "admin_devices_spaces",
            "admin_groups_page",
            "admin_models_sku_page",
            "admin_security_list_page",
            "admin_spaces_groups",
            "admin_sensors",
            "admin_users_groups",
            "admin_users_logs_page",
            "admin_users_page",
            'dashboard_page',
            'faq_page', 
            "home_page",
            "login_page",
            'main', 
            "reset_password_page",
            "request_password_page",
            "tracker_page",
            "welcome_page",
            "api_responses" 
        ],
        defaultNS: 'main',
        debug: true,
        interpolation: {},

        react: {
            wait: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        }
});

export default i18n;