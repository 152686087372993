import React from "react";

import { Box, Table, TableHead, TableCell, TableBody, TableRow } from "@mui/material";

const PrintComponent = React.forwardRef((props, ref) => {
	const { data = [] } = props;
  
	const rows = data.map((row) => {
		const itemsArray = []
		const editedRow = { ...row };
		delete editedRow.id;
	
		for (const key in editedRow) {
			itemsArray.push(<TableCell sx={{ maxHeight: "25px" }}>{editedRow[key]}</TableCell>)
		}

		const jsxRow = <TableRow>{itemsArray}</TableRow>;

		return jsxRow;
	});

	return (
		<Box ref={ref}>
			<Table>
				<TableHead>
					{data.length && Object.keys(data?.at(0)).filter((head) => head !== "id").map((head) => (
						<TableCell sx={{ maxHeight: "25px" }}>{head.charAt(0).toUpperCase() + head.slice(1)}</TableCell>
					))}
				</TableHead>
				<TableBody>{rows}</TableBody>
			</Table>
		</Box>
	);
});

export default PrintComponent;
