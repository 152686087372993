import { createTheme as createMuiTheme } from "@mui/material/styles";
import baseThemeOptions from "./base-theme-options";
import lightThemeOptions from "./light-theme-options";
import darkThemeOptions from "./dark-theme-options";

const createTheme = (mode) => {
	const theme = createMuiTheme(baseThemeOptions, mode === "dark" ? darkThemeOptions : lightThemeOptions);

	return theme;
};

export default createTheme;
