import React, {useState} from "react";

/* MUI */
import {Menu, Divider, MenuItem, ListItemText, Box} from "@mui/material"
import { makeStyles } from "@mui/styles";
import InfoIcon from '@mui/icons-material/Info'

const useStyles = makeStyles(theme => ({
	container: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer"
	},
	link: {
		textDecoration: "none",
		color: theme.palette.text.primary
	}
}));

const Info = props => {
	const { history, t } = props;
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const goTo = path => {
		history.push(`/${path}`);
		handleClose();
	};

	const goToAcromove = () => {
		window.location.href = "https://acromove.com/contact/"
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.container} onClick={handleClick}>
				<InfoIcon />
			</Box>
			<Menu id="info-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => goToAcromove()} button>
					<ListItemText>{t("header.contactUs")}</ListItemText>
				</MenuItem>
				<Divider />
				<MenuItem
					onClick={() => {
						goTo("faq");
					}}
					button
				>
					<ListItemText>{t("header.faq")}</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default Info;
