import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const deviceModelsAPI = createApi({
	reducerPath: "deviceModels/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("devices/models")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindModels"],
	endpoints: build => ({
		findModels: build.query({
			query: params => ({
				url: "find",
				params
			}),
			transformResponse: response => response.payload,
			providesTags: ["FindModels"]
		}),
		addModel: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `add`,
					method: "POST",
					body: formData
				};
			},
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			}),
			invalidatesTags: ["FindModels"]
		}),
		editModel: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `edit`,
					method: "PATCH",
					body: formData
				};
			},
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			}),
			invalidatesTags: ["FindModels"]
		}),
		removeModel: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindModels"]
		}),

	})
});

export const {
	useFindModelsQuery,
	useLazyFindModelsQuery,
	useAddModelMutation,
	useEditModelMutation, 
	useRemoveModelMutation
} = deviceModelsAPI;

export default deviceModelsAPI;
