import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const groupsAPI = createApi({
	reducerPath: "groups/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("groups")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindGroups"],
	endpoints: build => ({
		findGroups: build.query({
			query: ({spaceId, ...params}) => ({
				url: "find",
				params: {
					...params,
					space_id: spaceId
				}
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items.map(item => ({
					...item,
					group: item.name
				}))
			}),
			providesTags: ["FindGroups"]
		}),
		findGroupStats: build.query({
			query: ({spaceId, ...params}) => ({
				url: "stats",
				params: {
					...params,
					space_id: spaceId,
				}
			}),
			transformResponse: (response) => ({
				total: response.payload.total,
				items: response.payload.items
			})
		}),
		addGroup: build.mutation({
			query: data => ({
				url: "add",
				method: "POST",
				body: data
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindGroups"]
		}),
		editGroup: build.mutation({
			query: ({ data, id }) => ({
				url: `edit/${id}`,
				method: "PATCH",
				body: data
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindGroups"]
		}),
		removeGroup: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindGroups"]
		}),
		changeGroupUsers: build.mutation({
			query: ({ groupId, ...data }) => ({
				url: `users/change/${groupId}`,
				method: "PATCH",
				body: data
			}),
			transformResponse: response => response.payload
		}),
		getGroupByUser: build.query({
			query: ({ userId, groupId }) => ({
				url: `users/${userId}/group/${groupId}`
			}),
			transformResponse: response => response.payload
		}),
		findGroupsInSpace: build.query({
			query: ({ spaceId }) => ({
				url: `find/in/space/${spaceId}`
			}),
			transformResponse: response => response.payload,
			providesTags: ["FindGroups"]
		}),
		isUserInGroup: build.query({
			query: ({userId, groupId}) => ({
				url: `/users/${userId}/has/group/${groupId}`
			}),
			transformResponse: (response) => response.payload.has
		})
	})
});

export const {
	useFindGroupsQuery,
	useLazyFindGroupsQuery,
	useAddGroupMutation,
	useEditGroupMutation,
	useRemoveGroupMutation,
	useChangeGroupUsersMutation,
	useFindGroupStatsQuery,
	useLazyFindGroupStatsQuery,
	useGetGroupByUserQuery,
	useLazyGetGroupByUserQuery,
	useFindGroupsInSpaceQuery,
	useLazyFindGroupsInSpaceQuery,
	useIsUserInGroupQuery,
	useLazyIsUserInGroupQuery
} = groupsAPI;

export default groupsAPI;
