
import { Roles, API_URL } from "../constants/constants";
import _ from "lodash";
import moment from "moment-timezone";


export const findAllArrayPaths = (source, key) => {
	let paths = [];
	if (key.includes("[]")) {
		const startOfArray = key.indexOf("[]");
		const left = key.slice(0, startOfArray + 1);
		const right = key.slice(startOfArray + 1, key.length);
		const endpoint = left.slice(0, -1);

		const endpointValue = _.get(source, endpoint);

		if (endpointValue) {
			for (let index = 0; index < endpointValue.length; index++) {
				paths.push(`${left}${index}${right}`);
			}
		}

		for (const innerPath of paths) {
			paths = [...paths.filter((p) => p !== innerPath), ...findAllArrayPaths(source, innerPath)];
		}
		return paths;
	}
	return [key];
};

export function timeDifference(current, previous) {
	const msPerMinute = 60 * 1000;
	const msPerHour = msPerMinute * 60;
	const msPerDay = msPerHour * 24;
	const msPerMonth = msPerDay * 30;
	const msPerYear = msPerDay * 365;

	const elapsed = current - previous;

	if (elapsed < msPerMinute) {
		return `${Math.round(elapsed / 1000)} seconds ago`;
	}
	if (elapsed < msPerHour) {
		return `${Math.round(elapsed / msPerMinute)} minutes ago`;
	}
	if (elapsed < msPerDay) {
		return `${Math.round(elapsed / msPerHour)} hours ago`;
	}
	if (elapsed < msPerMonth) {
		return `approximately ${Math.round(elapsed / msPerDay)} days ago`;
	}
	if (elapsed < msPerYear) {
		return `approximately ${Math.round(elapsed / msPerMonth)} months ago`;
	}
	return `approximately ${Math.round(elapsed / msPerYear)} years ago`;
}

export const isEqual = (first = {}, second = {}) => {
	const firstSorted = {};
	const secondSorted = {};

	Object.keys(first)
		.sort()
		.forEach((key) => {
			firstSorted[key] = first[key];
		});

	Object.keys(second)
		.sort()
		.forEach((key) => {
			secondSorted[key] = second[key];
		});

	return JSON.stringify(firstSorted) === JSON.stringify(secondSorted);
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
// export function difference(object, base) {
// 	function changes(object, base) {
// 		return _.transform(object, (result, value, key) => {
// 			if (!_.isEqual(value, base[key])) {
// 				result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
// 			}
// 		});
// 	}
// 	return changes(object, base);
// }

export const cellsToFar = (cells) => {
	const far = (cells * 9) / 5 + 32;
	return parseFloat(far.toFixed(2));
};

export const metersToFt = (meters) => Math.round(meters * 3.2808);

export const sliceCords = (cord) => {
	if (!cord) return "N/A";
	const str = cord.toString();
	const beforeComma = str.substr(0, str.indexOf("."));
	const afterComma = str.substr(str.indexOf("."), 7);

	const output = `${beforeComma}${afterComma}`;
	return output;
};

export const getBackEndUrl = (path) => (path ? `${API_URL}${path}` : path);

export const isNumeric = (value) => /^-?\d+$/.test(value);

export const isJson = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return JSON.parse(str);
};

export const formatFilename = (filename) => {
	let file = filename;
	file = file && file.length >= 15 ? `${file.substr(0, 13)}[...]${file.substr(file.indexOf("."), 4)}` : file;
	return file;
};

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const utcToString = (date) => {
	const ld = new Date(date);
	const year = ld.getFullYear();
	const month = ld.getMonth() + 1 < 10 ? `0${ld.getMonth() + 1}` : ld.getMonth() + 1;
	const day = ld.getDate() < 10 ? `0${ld.getDate()}` : ld.getDate();
	const hours = ld.getHours() < 10 ? `0${ld.getHours()}` : ld.getHours();
	const mins = ld.getMinutes() < 10 ? `0${ld.getMinutes()}` : ld.getMinutes();
	const secs = ld.getSeconds() < 10 ? `0${ld.getSeconds()}` : ld.getSeconds();
	const milli = ld.getMilliseconds();
	return `${year}-${month}-${day}T${hours}:${mins}:${secs}.${milli}Z`;
};

export const getClearRole = (role) => (role.includes(":") ? role.split(":")[0] : role);

export const getRoleLabel = (role) => Roles.find((r) => r.value === getClearRole(role))?.label || null;

export const getRoleFromLabel = (label) => Roles.find((r) => r.label === label)?.value || null;

export const getRoles = (include = []) => (include.length ? Roles.filter((role) => include.includes(role.value)) : Roles);

// export const dateFormatter = (date, options) => {
// 	const { timezone = DateTime.local().zone.name, notation = "metric" } = options;
// 	const monthNames = [
// 		i18n.t("main:months.jan"),
// 		i18n.t("main:months.feb"),
// 		i18n.t("main:months.mar"),
// 		i18n.t("main:months.apr"),
// 		i18n.t("main:months.may"),
// 		i18n.t("main:months.jun"),
// 		i18n.t("main:months.jul"),
// 		i18n.t("main:months.aug"),
// 		i18n.t("main:months.sep"),
// 		i18n.t("main:months.oct"),
// 		i18n.t("main:months.nov"),
// 		i18n.t("main:months.dec"),
// 	];
// };

export const imageToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const imageToBase64ByLink = (url) =>
	new Promise((resolve) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			const reader = new FileReader();
			reader.onloadend = function () {
				resolve(reader.result);
			};
			reader.readAsDataURL(xhr.response);
		};
		xhr.open("GET", url);
		xhr.responseType = "blob";
		xhr.send();
	});

// export const objectDifference = (object, base) => {
// 	function changes(object, base) {
// 		return _.transform(object, (result, value, key) => {
// 			if (!_.isEqual(value, base[key])) {
// 				result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
// 			}
// 		});
// 	}
// 	return changes(object, base);
// };

// export const objectPaths = (o) => {
// 	const rKeys = (o, path = "") => {
// 		if (!o || typeof o !== "object") return path;
// 		return Object.keys(o).map((key) => rKeys(o[key], path ? [path, key].join(".") : key));
// 	};
// 	return rKeys(o).toString().split(",");
// };

// export const findSensorsInCategory = (integrated_devices) => {
// 	const categories = {
// 		map: 0,
// 		graph: 0,
// 		bargraph: 0,
// 	};

// 	for (const device of integrated_devices) {
// 		const { subsystems } = device;
// 		for (const subsystem of subsystems) {
// 			for (const sensor of subsystem.sensors) {
// 				const { category } = sensor;
// 				categories[category] = categories[category] + 1;
// 			}
// 		}
// 	}

// 	return categories;
// };

const constructColor = (hexString) => {
	const hex = hexString.replace(/#/g, "");
	/* Get the RGB values to calculate the Hue. */
	const r = parseInt(hex.substring(0, 2), 16) / 255;
	const g = parseInt(hex.substring(2, 4), 16) / 255;
	const b = parseInt(hex.substring(4, 6), 16) / 255;

	/* Getting the Max and Min values for Chroma. */
	const max = Math.max.apply(Math, [r, g, b]);
	const min = Math.min.apply(Math, [r, g, b]);

	/* Variables for HSV value of hex color. */
	const chr = max - min;
	let hue = 0;
	const val = max;
	let sat = 0;

	if (val > 0) {
		/* Calculate Saturation only if Value isn't 0. */
		sat = chr / val;
		if (sat > 0) {
			if (r === max) {
				hue = 60 * ((g - min - (b - min)) / chr);
				if (hue < 0) {
					hue += 360;
				}
			} else if (g === max) {
				hue = 120 + 60 * ((b - min - (r - min)) / chr);
			} else if (b === max) {
				hue = 240 + 60 * ((r - min - (g - min)) / chr);
			}
		}
	}
	const colorObj = {};
	colorObj.hue = hue;
	colorObj.hex = hexString;
	return colorObj;
};

export const sortColorsByHue = (colors) =>
	colors
		.map((color) => constructColor(color))
		.sort((a, b) => a.hue - b.hue)
		.map((color) => color.hex);

export const generateList = (actions, list, isAllowed) => {
	const newList = list.filter((element) => {
		if (isAllowed(`pages:${`${actions}_${element.path.replace("-", "_")}_view`}`)) {
			return element;
		}
		return null
	});
	return newList;
};

export const redirectOnInit = (user, actions, page, location, history, setSelected, isAllowed) => {
	if (!user) {
		history.push("/login");
	}

	const tool = location.search.substring(1, location.search.length);
	if (isAllowed(`pages:${`${actions}_${tool.replace("-", "_")}_view`}`)) {
		setSelected(tool);
	} else {
		history.push(`/${page}?dashboard`);
	}
};

export const toggleDrawer = (open, setOpen) => (event) => {
	if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
		return;
	}
	setOpen(open);
};

export const toJsDateWithTimezone = (date, timezone) => {
	const withTimezone = moment(date).tz(timezone)
	const [year, month, day, hours, minutes, seconds] = withTimezone.format("YYYY|MM|DD|HH|mm|ss").split("|").map(d => +d);
	const formatted = new Date()
	formatted.setFullYear(year)
	formatted.setMonth(month-1)
	formatted.setDate(day)
	formatted.setHours(hours)
	formatted.setMinutes(minutes)
	formatted.setSeconds(seconds)
	return formatted;
}

export const returnLocaleOrFalse = (key, translation) => key === translation ? false : translation
