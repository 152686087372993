import React from "react";

/* MUI */
import { TextField } from "@mui/material";

const SmallTextField = props => {
	const {
		label,
		placeholder,
		className,
		onChange,
		InputProps = {},
		innerClassName,
		value,
		name,
		input,
		disabled = false,
		onBlur = false,
		sx = {},
		...rest
	} = props;

	return (
		<TextField
			sx={{width: "100%", ...sx}}
			size="small"
			{...rest}
			inputProps={{ disabled }}
			disabled={disabled}
			InputProps={InputProps}
			name={name}
			value={value}
			onChange={e => {
				if (input) input.onChange(e);
				onChange(e);
			}}
			onFocus={e => {
				if (input) input.onFocus(e);
			}}
			onBlur={e => {
				if (onBlur) onBlur(e);
			}}
			label={label}
            placeholder={placeholder}
			variant="outlined"
		/>
	);
};

export default SmallTextField;
