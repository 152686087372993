import React, { useState } from "react";

import { List, ListItem, Drawer, Typography, Box, ListItemText, IconButton, useMediaQuery } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

/* Components */
import { Link } from "react-router-dom";

/* Utils */
import ProtectedComponent from "../../authentication/components/protected";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	paper: {
		height: "100%",
		backgroundColor: theme.palette.background.paper,
		opacity: 0.8,
	},
	title: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing.unit / 2,
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	toolbar: {
		textAlign: "right",
		padding: 15,
	},
	anchor: {
		color: theme.palette.text.secondary,
	},
	logo: {
		display: "inline-block",
		height: "80px",
		lineHeight: "80px",
		letterSpacing: "5px",
	},
	userLabel: {
		color: theme.palette.text.primary,
		cursor: "pointer",
		fontSize: "0.875rem",
		padding: `0 ${theme.spacing.unit * 2}px 0 0`,
	},
	listItem: {
		textAlign: "center",
	},
}));

const iOS = !(typeof window === "undefined") && /iPad|iPhone|iPod/.test(navigator.userAgent);
const AppDrawer = (props) => {
	const { onClose, onOpen, mobileOpen, user } = props;
	const { t } = useTranslation("main");
	const classes = useStyles();
	const [selected, setSelected] = useState({});
	const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const onItemClick = (selectedItem) => () => {
		setSelected({ selectedItem });
	};

	const goToAcromove = () => {
		window.location.href = "https://acromove.com/contact/";
	};

	const drawer = () => (
		<Box className={classes.nav}>
			<Box className={classes.toolbar}>
				<IconButton color="inherit" aria-label="open drawer" onClick={onClose}>
					<Close />
				</IconButton>
			</Box>
			<List component="nav">
				<ListItem key="faq" component={Link} to="/faq" onClick={onClose}>
					<ListItemText className={classes.listItem}>{t("header.faq")}</ListItemText>
				</ListItem>
				<ListItem key="acromove" onClick={() => goToAcromove()} className={classes.listItem}>
					<ListItemText className={classes.listItem}>{t("header.contactUs").toUpperCase()}</ListItemText>
				</ListItem>
				{user ? (
					<>
						<ListItem key="dashboard" component={Link} to="/dashboard" onClick={onClose}>
							<ListItemText className={classes.listItem}>{t("header.dashboard")}</ListItemText>
						</ListItem>
						<ListItem key="tracker" component={Link} to="/tracker" onClick={onClose}>
							<ListItemText className={classes.listItem}>{t("header.tracker")}</ListItemText>
						</ListItem>
						<ProtectedComponent key="superadmin" rule="pages:superadmin_view">
							<ListItem
								component={Link}
								onClick={() => {
									onItemClick("admin");
									onClose();
								}}
								className={classNames(
									classes.headerTitle,
									selected === "superadmin" ? classes.selected : ""
								)}
								to="/superadmin?dashboard"
							>
								<ListItemText className={classes.listItem}>{t("header.admin")}</ListItemText>
							</ListItem>
						</ProtectedComponent>
						<ProtectedComponent key="admin" rule="pages:admin_view">
							<ListItem
								component={Link}
								onClick={() => {
									onItemClick("admin");
									onClose();
								}}
								className={classNames(classes.headerTitle, selected === "admin" ? classes.selected : "")}
								to="/admin"
							>
								<ListItemText className={classes.listItem}>{t("header.admin")}</ListItemText>
							</ListItem>
						</ProtectedComponent>
						<ProtectedComponent key="manager" rule="pages:manager_view">
							<ListItem
								component={Link}
								onClick={() => {
									onItemClick("manager");
									onClose();
								}}
								className={classNames(classes.headerTitle, selected === "manager" ? classes.selected : "")}
								to="/manager"
							>
								<ListItemText className={classes.listItem}>{t("header.manager")}</ListItemText>
							</ListItem>
						</ProtectedComponent>
					</>
				) : (
					[
						<ListItem key="login" component={Link} to="/login" onClick={onClose}>
							<ListItemText className={classes.listItem}>
								<Typography color="primary" variant="subtitle1">
									{t("header.signin")}
								</Typography>
							</ListItemText>
						</ListItem>,
					]
				)}
			</List>
		</Box>
	);

	return (
		isDownMd && (
			<Drawer
				classes={{
					paper: classes.paper,
				}}
				anchor="top"
				disableBackdropTransition={!iOS}
				variant="temporary"
				open={mobileOpen}
				onOpen={onOpen}
				onClose={onClose}
				ModalProps={{
					keepMounted: true,
				}}
			>
				{drawer()}
			</Drawer>
		)
	);
};

export default AppDrawer;
