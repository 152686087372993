import React, {useEffect, useRef} from "react";
import toast from "react-hot-toast"

const InputFile = (props) => {
    const {
        formik,
        exts = [],
        name = "",
        uploadName = "",
        imageComponent = () => {}
    } = props;

    const imageRef = useRef(null);

    const handleClear = () => {
        formik.setFieldValue(name, null)
        formik.setFieldValue(uploadName, null)
    }

    useEffect(() => {
        if(formik.errors[uploadName] && formik.values[uploadName]){
            toast.error(formik.errors[uploadName])
            handleClear()
        }
    }, [formik.errors[uploadName]])


    const validateFileName = (file) => {
        let localFile = file;
        const fileName = localFile.name.replace(/ |-/g, '_');
        localFile = new File([localFile], fileName, {type: localFile.type});
        return localFile;
    }

    const onUpload = async (newFile) => {
        
            const formattedFile = validateFileName(newFile);
            formik.setFieldValue(name, formattedFile)
            formik.setFieldValue(uploadName, formattedFile)
        
    }


    const onClick = () => {
        imageRef.current.click();
    }

    return(
        <>
        <input
            ref={imageRef}
            type="file" 
            style={{ display: "none" }}
            accept={exts.join(", ")}
            onChange={(e) => onUpload(e.target.files[0])}
            /> 
            {imageComponent({onUpload: onClick, clear: handleClear, image: formik.values[name]})}
        </>
    );

}

export default InputFile;