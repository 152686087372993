import * as React from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(() => ({
	link: {
		"& * ": {
			fontWeight: 500
		}
	},
	baseLink: {
		margin: "0 5px"
	}
}));


const ButtonLink = (props) => {
	const { typoClass, onClick = () => {}, path, color, variant, translationKey } = props;
	const classes = useStyles();
	const { t } = useTranslation("main");
	return (
		<Button component={NavLink} to={path} onClick={onClick} activeClassName={classes.link} className={classes.baseLink}>
			<Typography className={typoClass} variant={variant || "h6"} color={color || "inherit"}>
				{t(translationKey)}
			</Typography>
		</Button>
	);
};

export default ButtonLink;
