import React from "react";
import { Route, Redirect } from "react-router-dom";
import { RolesDefPage } from "../../../constants/constants";
import useAuth from "authentication/hooks/use-auth";
import { useHistory } from "react-router";
import Loader from "@components/shared/ui/Loader";

const PrivateRoute = ({ component: Component, permissions, slug, ...rest }) => {
	const { isAllowed, user, isAuthenticated } = useAuth();
	const history = useHistory();
	return !isAuthenticated ? (
		<Loader sx={{display: "flex", marginTop: "200px"}}/>
	) : (
		<Route
			{...rest}
			render={(props) => {
				if (user && isAllowed(`pages:${slug}`)) {
					return <Component {...props} />;
				}
				const role = user && user.role ? user.role : "none";
				const defPage = role in RolesDefPage ? RolesDefPage[role] : "dashboard";
				history.push(`/${defPage}`);
				return (
					<Redirect
						to={{
							pathname: `/${defPage}`,
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

export default PrivateRoute;
