import React, { useState, useEffect } from "react";

/* Redux */
import { useSelector, useDispatch } from "react-redux";

/* MUI */
import { Dialog, DialogContent, DialogTitle, Card, CardHeader } from "@mui/material";

/* Components */
import Loader from "../shared/ui/Loader";
import ProfileForm from "./ProfileForm";
import { useEditMeMutation, useChangeMyPasswordMutation, authAPI } from "@redux/auth/api";

/* Util */
import { useTranslation } from "react-i18next";
import { useAppEvents } from "general/hooks";

const ProfilePopup = (props) => {
	const { isOpen, handleClose } = props;
	const { t } = useTranslation("main", { keyPrefix: "userProfile" });
	const dispatch = useDispatch();
	const [allowEdit, setAllowEdit] = useState(false);
	const [editPassword, setEditPassword] = useState(false);
	const [localData, setLocalData] = useState({});
	const [clear, setClear] = useState(false);
	const [loading, setLoading] = useState(false);

	const data = useSelector((state) => state.auth.user);
	const { onError, onSuccess } = useAppEvents("main:userProfile");
	const [editMe] = useEditMeMutation();
	const [editMyPassword] = useChangeMyPasswordMutation();

	useEffect(() => {
		setLocalData({ ...data });
	}, [data]);

	useEffect(() => {
		if (clear) setClear(false);
	}, [clear]);

	const toggleEditPassword = () => {
		setEditPassword(!editPassword);
	};

	const toggleEdit = () => {
		setAllowEdit(!allowEdit);
		setLocalData({ ...data });
		setClear(true);
	};

	const onSave = async (formData) => {
		setLoading(true);
		const resp = await editMe(formData);
		setLoading(false);
		if (resp && resp.data && resp.data.success) {
			onSuccess(t("form.notifications.data"));
		} else {
			onError(t("form.errors.data"));
		}
		dispatch(authAPI.util.invalidateTags(["GetMe"]));
		toggleEdit();
	};

	const onSavePassword = async (password) => {
		setLoading(true);
		const resp = await editMyPassword({ password });
		setLoading(false);
		if (resp.data.success) {
			onSuccess(t("form.notifications.password"));
		} else {
			onError(t("form.errors.password"));
		}
		toggleEditPassword();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				handleClose();
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Card sx={{ minHeight: "480px", position: "relative" }}>
				{loading && (
					<Loader
						sx={{
							position: "absolute",
							width: "100%",
							height: "100%",
							backgroundColor: "background.paper",
							opacity: "0.7",
						}}
					/>
				)}
				<DialogTitle id="alert-dialog-title" style={{ padding: 0 }}>
					<CardHeader
						sx={{
							backgroundColor: "primary.main",
							textAlign: "center",
							maxHeight: "40px",
							color: "background.paper",
						}}
						title="User Profile"
					/>
				</DialogTitle>
				<DialogContent sx={{ width: "100%", height: "100%" }}>
					<ProfileForm
						clear={clear}
						onSavePassword={onSavePassword}
						toggleEditPassword={toggleEditPassword}
						editPassword={editPassword}
						toggleEdit={toggleEdit}
						allowEdit={allowEdit}
						onSave={onSave}
						handleClose={handleClose}
						data={localData}
					/>
				</DialogContent>
			</Card>
		</Dialog>
	);
};

export default ProfilePopup;
