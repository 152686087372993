import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const appAPI = createApi({
	reducerPath: "app/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("app/settings")),
	keepUnusedDataFor: 30,
	endpoints: build => ({
		getSettings: build.query({
			query: () => ({
				url: "",
			}),
			transformResponse: (response) => response.payload,
		}),
        setSettings: build.mutation({
            query: (data) => ({
				url: "",
                method: 'POST',
                body: data
			}),
            transformResponse: (response) => response.payload,
        }),
    })
});


export const {
    useGetSettingsQuery,
	useLazyGetSettingsQuery,
    useSetSettingsMutation
} = appAPI;

export default appAPI
