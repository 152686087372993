import React, { useState } from "react";
import { Autocomplete, TextField, CircularProgress, Box } from "@mui/material";

const InputAutocomplete = (props) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
		
        formik,
        label,
        placeholder,
        name,
        valueKey = "id",
        nameKey = "name",
        onSearch = () => {},
        disabled = false,
		helperText,
		
    } = props;

    const handleType = async (e) => {
        const {target: {value}} = e;
        setLoading(true);
        const result = await onSearch(value);
        setOptions(result);
        setLoading(false);
    }

    return(
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <Box sx={{width: "100%"}}>
                <Autocomplete
                    disabled={disabled}
                    options={options}
                    error={formik.errors[name] && formik.errors[name][valueKey]}
                    onChange={(e, val)=> formik.setFieldValue(name, val)}
                    autoHighlight
                    getOptionLabel={(option) => (option[nameKey] ? option[nameKey] : "")}
                    isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]}
                    getOptionSelected={(option, value) => option[valueKey] === value[valueKey]}
                    loading={loading}
                    loadingText={<CircularProgress color="primary"/>}
                    value={formik.values[name] ? formik.values[name] : null}
                    clearOnEscape
                    renderInput={(params) => (
                        <TextField
                            sx={{width: "100%"}}
                            {...params}
                            error={formik.errors[name] && formik.errors[name][valueKey]}
                            size="small"
                            label={label}
                            variant="outlined"
                            onChange={handleType}
                            placeholder={placeholder}
                            helperText={helperText}
                            disabled={disabled}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}

                        />
                    )}
                />
                </Box>
            </Box>

    );
}

export default InputAutocomplete;
