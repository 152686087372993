
export default {
    $default: "t:api_responses:responses",
    "users/api": "t:admin_users_page:form.validation_errors",
    "billing/api": "t:admin_billing_page:invoice_form.validation_errors",
    "devices/api": "t:admin_devices_keys_page:device_form.validation_errors",
    "groups/api": "t:admin_groups_page:space_form.validation_errors",
    "deviceModels/api": "t:admin_models_sku_page:model_form.validation_errors",
    "deviceSKU/api": "t:admin_models_sku_page:sku_form.validation_errors",
    "integratedDevices/api": "t:admin_models_sku_page:integrated_device.validation_errors",
    "securityList/api": "t:admin_security_list:form.validation_errors",
    "sensors/api": "t:admin_sensors:sensors_form.form.validation_errors",
    "auth/api": "t:main:userProfile.form.validation_errors",
    "logs/api": "t:admin_users_logs_page:form.validation_errors"
}