import { configureStore, combineReducers, isRejectedWithValue } from "@reduxjs/toolkit";

/* API */
import app, { addEvent } from "./app/slice";
import auth, { logoutUser } from "./auth/slice";
import dashboard from "./dashboard/slice";
import oneSensor from "./sensors/oneSensor.slice";
import tracker from "./tracker/slice";
import trackerData from "./tracker/trackerData.slice";
import graphs from "./tracker/graphs.slice";
import source from "./deviceSKU/source.slice"
import { appAPI } from "./app/api";
import { authAPI } from "./auth/api";
import { spacesAPI } from "./spaces/api";
import { groupsAPI } from "./groups/api";
import { devicesAPI } from "./devices/api";
import { deviceModelsAPI } from "./deviceModels/api";
import { deviceSkuAPI } from "./deviceSKU/api";
import { integratedDevicesAPI } from "./integratedDevices/api";
import { userLogsAPI } from "./userLogs/api";
import { filtersAPI } from "./filters/api";
import { parsersAPI } from "./parsers/api";
import { securityListAPI } from "./securityList/api";
import { sensorsAPI } from "./sensors/api";
import { usersAPI } from "./users/api";
import { billingAPI } from "./billing/api";
import { servicesAPI } from "./services/api";
import { commandsAPI } from "./commands/api";
import i18n from "../i18n";

import i18nValidationErrors from "./i18n-validation-errors";

const rootReducer = combineReducers({
	app,
	auth,
	dashboard,
	oneSensor,
	tracker,
	trackerData,
	graphs,
	source,
	[appAPI.reducerPath]: appAPI.reducer,
	[authAPI.reducerPath]: authAPI.reducer,
	[spacesAPI.reducerPath]: spacesAPI.reducer,
	[groupsAPI.reducerPath]: groupsAPI.reducer,
	[devicesAPI.reducerPath]: devicesAPI.reducer,
	[deviceModelsAPI.reducerPath]: deviceModelsAPI.reducer,
	[deviceSkuAPI.reducerPath]: deviceSkuAPI.reducer,
	[integratedDevicesAPI.reducerPath]: integratedDevicesAPI.reducer,
	[userLogsAPI.reducerPath]: userLogsAPI.reducer,
	[filtersAPI.reducerPath]: filtersAPI.reducer,
	[parsersAPI.reducerPath]: parsersAPI.reducer,
	[securityListAPI.reducerPath]: securityListAPI.reducer,
	[sensorsAPI.reducerPath]: sensorsAPI.reducer,
	[usersAPI.reducerPath]: usersAPI.reducer,
	[billingAPI.reducerPath]: billingAPI.reducer,
	[servicesAPI.reducerPath]: servicesAPI.reducer,
	[commandsAPI.reducerPath]: commandsAPI.reducer
});

const rtkQueryResponseLogger = () => (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const {
			payload: { data = { message: "" } },
		} = action;
		if (!data?.message) return next(action);
		if (data.message.startsWith("error.validation_extended")) {
			api.dispatch(
				addEvent({
					severity: "error",
					type: "popup",
					list: data.payload.list,
					name: data.message.replace("error.validation_extended.", ""),
				})
			);
		} else if (data.message.startsWith("error.validation")) {
			const typeParts = action.type.split("/");
			const reducerPath = `${typeParts.at(0)}/${typeParts.at(1)}`;
			const list =
				reducerPath in i18nValidationErrors
					? data.payload.map((msg) => {
							const message = `${i18nValidationErrors[reducerPath]}.${msg}`;
							console.log(message);
							if (message.startsWith("t:")) {
								if (i18n.exists(message.slice(2))) {
									return message;
								}
								return `${i18nValidationErrors.$default}.${msg}`;
							}
							return message;
					  })
					: data.payload.map((msg) => `${i18nValidationErrors.$default}.${msg}`);
			api.dispatch(
				addEvent({
					severity: "error",
					type: "notification",
					list,
					message: data.message,
					name: "multiple",
				})
			);
		} else if (data.message.startsWith("error.not_allowed")) {
			api.dispatch(
				addEvent({
					severity: "error",
					type: "notification",
					message: `${i18nValidationErrors.$default}.${data.message}`,
				})
			);
		}
	}

	return next(action);
};

const MWs = [
	appAPI.middleware,
	authAPI.middleware,
	spacesAPI.middleware,
	groupsAPI.middleware,
	devicesAPI.middleware,
	deviceModelsAPI.middleware,
	deviceSkuAPI.middleware,
	integratedDevicesAPI.middleware,
	userLogsAPI.middleware,
	filtersAPI.middleware,
	parsersAPI.middleware,
	securityListAPI.middleware,
	sensorsAPI.middleware,
	usersAPI.middleware,
	billingAPI.middleware,
	servicesAPI.middleware,
	commandsAPI.middleware,
	rtkQueryResponseLogger(),
];

const store = configureStore({
	reducer: (state, action) => {
		if (logoutUser.match(action)) {
			state = undefined; //eslint-disable-line
		}
		return rootReducer(state, action);
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(MWs),
});

window.store = store;

export default store;
