import React, { useState } from "react";

/* MUI */
import { Menu, MenuItem, ListItemText, InputLabel, Box, useTheme, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PersonOutline from "@mui/icons-material/PersonOutline";
import ProfilePopup from "./ProfilePopup";

/* Redux */
import useAuth from "../../authentication/hooks/use-auth";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "@redux/app/slice";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	link: {
		textDecoration: "none",
		color: theme.palette.text.primary,
	},
	userLabel: {
		color: theme.palette.text.primary,
		cursor: "pointer",
		fontSize: "0.875rem",
		padding: `0 ${theme.spacing.unit * 2}px 0 0`,
	},
}));

const UserProfile = (props) => {
	const { user, t } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [profileOpen, setProfileOpen] = useState(false);
	const classes = useStyles();
	const theme = useTheme();
	const mode = useSelector((state) => state.app.mode);
	const dispatch = useDispatch();
	const auth = useAuth();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openProfile = () => {
		setProfileOpen(true);
	};

	const closeProfile = () => {
		setProfileOpen(false);
		document.querySelector("body").style.cssText = "overflow-y: scroll;";
	};

	return (
		<Box className={classes.root}>
			<ProfilePopup isOpen={profileOpen} handleClose={closeProfile} />
			<Box className={classes.container} onClick={handleClick}>
				<PersonOutline />
				<InputLabel htmlFor="user-profile-menu" className={classes.userLabel}>
					{user.name}
				</InputLabel>
			</Box>
			<Menu id="user-profile-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem
					autoFocus={false}
					sx={{
						borderBottom: "1px solid",
						borderBottomColor: "primary.main",
						cursor: "default",
						backgroundColor: "transparent !important",
						fontWeight: "700 !important",
						"&:hover": { backgroundColor: "transparent !important" },
					}}
				>
					<ListItemText disableTypography sx={{ fontWeight: "700 !important" }}>
						{user.firstname}
					</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={() => {
						handleClose();
						openProfile();
					}}
					button
				>
					<ListItemText>{t("userProfile.profile")}</ListItemText>
				</MenuItem>

				<MenuItem onClick={() => dispatch(setMode(mode === "dark" ? "light" : "dark"))}>
					Theme:
					<IconButton
						sx={{ ml: 1 }}
						onClick={() => dispatch(setMode(mode === "dark" ? "light" : "dark"))}
						color="inherit"
					>
						{theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
					</IconButton>
				</MenuItem>
				<MenuItem onClick={auth.logout} button>
					<ListItemText>{t("header.logout")}</ListItemText>
				</MenuItem>
			</Menu>
		</Box>
	);
};

export default UserProfile;
