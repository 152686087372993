import React, { useState } from "react";

/* MUI */
import { Typography, Button, Box } from "@mui/material";

/* Components */
import DialogPopup from "@components/common/DialogPopup/Dialog";
import SelectableTabel from "@components/common/SelectableTable";

/* Utils */
import { useTranslation } from "react-i18next";
import { useFindSpacesQuery } from "@redux/spaces/api";
import SelectableTableProvider from "@components/common/SelectableTable/selectable-table-provider";

const InputSpaceSelect = props => {
	const { t } = useTranslation("main", { keyPrefix: "defaults.input_space_select" });
	const {
		formik,
		disabled,
		name,
		onChange = () => {},
		label = t("label"),
		placeholder = t("placeholder"),
		selectLabel = t("btns.select")
	} = props;
	
	const { data, isLoading } = useFindSpacesQuery();

	const [selected, setSelected] = useState({ id: null });
	const [open, setOpen] = useState(false);

	const handleOpen = async () => {
		setOpen(true);
	};

	const columns = { name: { title: t("table.space"), width: 100 } };

	const onSelect = async id => {
		setSelected(data.items.filter(row => row.id === id)[0]);
	};

	const onClose = isAgree => {
		if (isAgree) {
			formik.setFieldValue(name, selected);
			onChange(formik, selected);
		} else {
			formik.setFieldTouched(name, true);
		}
		setOpen(false);
	};

	return (
		<Box sx={{ width: "100%", position: "relative" }}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					borderColor: formik.touched[name] && formik.errors[name] && "error.main"
				}}
			>
				<fieldset
					style={{
						width: "100%",
						borderRadius: "8px",
						borderColor: formik.touched[name] && formik.errors[name] && "error.main"
					}}
					>
					<legend>{label}</legend>
					<Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
						<Typography fontWeight={700} sx={{ ml: 1, fontSize: "1.1em" }}>
							{formik.values[name] && formik.values[name].id ? formik.values[name].name : placeholder}
						</Typography>
						<Button variant="contained" disabled={disabled} size="small" onClick={handleOpen} color="primary">
							{selectLabel}
						</Button>
					</Box>
				</fieldset>
				{formik.touched[name] && formik.errors[name] && <Box sx={{color: "error.main", alignSelf: "flex-start", fontSize: "12px", ml: 2, mt: 0.5}}>{formik.errors[name].id}</Box>}
			</Box>
			<DialogPopup open={open} handleClose={onClose}>
				<SelectableTableProvider useHook={useFindSpacesQuery}>
					<SelectableTabel
						columns={columns}
						selected={selected.id}
						loading={isLoading}
						useTools={false}
						onSelect={onSelect}
					/>
				</SelectableTableProvider>
			</DialogPopup>
		</Box>
	);
};

export default InputSpaceSelect;
