import useAuth from "../../hooks/use-auth";

const ProtectedComponent = ({ rule, children }) => {
	const auth = useAuth();
	if (auth.isAllowed(rule)) {
		return children;
	} 
	return null;
};

export default ProtectedComponent;
