import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";
import i18n from "../../i18n";

export const spacesAPI = createApi({
	reducerPath: "spaces/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("spaces")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindSpaces"],
	endpoints: build => ({
		findSpaces: build.query({
			query: params => ({
				url: "find",
				params
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items.map(item => ({
					...item,
					id: item.id,
					space: item.name,
					paid: item.isPaid
						? i18n?.t("admin_billing_page:space_table.paid_yes")
						: i18n?.t("admin_billing_page:space_table.paid_no"),
					status: item.isDisabled
						? i18n?.t("admin_billing_page:space_table.status_disabled")
						: i18n?.t("admin_billing_page:space_table.status_enabled")
				}))
			}),
			providesTags: ["FindSpaces"]
		}),
		findOneSpace: build.query({
			query: ({id, ...params}) => ({
				url: `find/${id}`,
				params
			}),
			transformResponse: response => response.payload
		}),
		addSpace: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: "add",
					method: "POST",
					body: formData
				};
			},
			transformResponse: response => response.payload,
			invalidatesTags: ["FindSpaces"]
		}),
		editSpace: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: "edit",
					method: "PATCH",
					body: formData
				};
			},
			transformResponse: response => response.payload,
			invalidatesTags: ["FindSpaces"]
		}),
		removeSpace: build.mutation({
			query: ({ id }) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindSpaces"]
		}),
		findSpacesStats: build.query({
			query: params => ({
				url: "stats",
				params
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items
			})
		}),
		changeStatus: build.mutation({
			query: ({ id, data }) => ({
				url: `disable/${id}`,
				method: "PATCH",
				body: data
			}),
			transformResponse: response => response.payload,
			invalidatesTags: ["FindSpaces"]
		}),
		getRevenues: build.query({
			query: () => ({
				url: "revenues"
			}),
			transformResponse: response => response.payload
		}),
		getOneRevenue: build.query({
			query: ({ id }) => ({
				url: `revenues/${id}`
			}),
			transformResponse: response => ({
				items: response.payload
			})
		})
	})
});

export const {
	useFindSpacesQuery,
	useLazyFindSpacesQuery,
	useAddSpaceMutation,
	useEditSpaceMutation,
	useRemoveSpaceMutation,
	useFindSpacesStatsQuery,
	useLazyFindSpacesStatsQuery,
	useChangeStatusMutation,
	useGetRevenuesQuery,
	useLazyGetRevenuesQuery,
	useGetOneRevenueQuery,
	useLazyGetOneRevenueQuery,
	useFindOneSpaceQuery,
	useLazyFindOneSpaceQuery
} = spacesAPI;

export default spacesAPI;
