import { API_URL } from "../constants/constants";

const getBaseQueryWithAuthorization = uri => ({
	baseUrl: `${API_URL  }/${  uri}`,
	prepareHeaders: headers => {
		const token = localStorage.getItem("accessToken");
		if (token) {
			headers.set("x-access-token", `${token}`);
		}
		return headers;
	},
	credentials: "include"
});

export default getBaseQueryWithAuthorization