import { createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone"
/* Initial State & State structure */

const initialState = {
	user: null,
    isUTC: null,
	isAuthenticated: false,
	isInitialized: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		initAuth(state, action) {
			const { isAuthenticated, user } = action.payload;
			state.isAuthenticated = isAuthenticated;
			state.isInitialized = true;
			state.user = {
				...user,
				timezone: action.payload?.timezone || moment.tz.guess()
			};
		},
		setUser(state, action) {
			console.log("anar",moment.tz.guess());
			state.user = {
				...action.payload,
				timezone: action.payload?.timezone || moment.tz.guess()
			};
		},
		logoutUser(state) {
			state.isAuthenticated = false;
      		state.user = null;
		},
		setIsUTC(state, action) {
            state.isUTC = action.payload
        }
	}
});

export const { setUser, setIsUTC, initAuth, logoutUser } = authSlice.actions;

export default authSlice.reducer
