import React from "react";
import "./i18n";
import "./index.css";

/* Components */
import App from "./App";

/* MUI */
import { LicenseInfo } from "@mui/x-license-pro";

/* Auth and Router */
import { AuthProvider } from "./authentication/context/jwt";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";

/* Redux */
import { Provider } from "react-redux";
import store from "./redux";

const container = document.getElementById("root");
const root = createRoot(container);

LicenseInfo.setLicenseKey(
	"57db7a9560009af47909ea16a77df288Tz00NzkwMixFPTE2OTAzNjUxODM0MjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

root.render(
	<>
		<Toaster position="top-right" containerStyle={{ marginTop: "75px" }} />

		<Provider store={store}>
			<BrowserRouter>
				<AuthProvider publicRoutes={["/", "/welcome", "/login", "/requestPasswordReset", "/resetPassword", "/faq"]}>
					<App />
				</AuthProvider>
			</BrowserRouter>
		</Provider>
	</>
);

// g-m-tmsp

/* For Debug! */
window.getErrors = () => JSON.stringify(window.store.getState().all.eventList);
document.addEventListener("keydown", (e) => {
	if (e.altKey && e.ctrlKey && (e.key === "l" || e.key === "L")) {
		const win = window.open(
			"/",
			"Errors LOG",
			`menubar=false,
                                              location=false,
                                              resizable=false,
                                              status=false,
                                              width=800,
                                              height=600`
		);
		win.document.write(`
      <h1>AcroTrace Errors Log:</h1>
      <pre>${JSON.stringify(window.store.getState().all.eventList, null, 2)}</pre>
    `);
	}
});
