import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const authAPI = createApi({
	reducerPath: "auth/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("")),
	keepUnusedDataFor: 30,
	tagTypes: ["GetMe"],
	endpoints: build => ({
		login: build.mutation({
			query: ({ username, password }) => ({
				url: "auth/login",
				method: "POST",
				body: {
					username,
					password
				}
			}),
			transformResponse: response => response.payload
		}),
		getMe: build.query({
			query: () => ({
				url: "user/me"
			}),
			transformResponse: response => response.payload,
			providesTags: ["GetMe"]
		}),
		logout: build.query({
			query: () => ({
				url: "user/logout"
			}),
			transformResponse: response => response.payload
		}),
		editMe: build.mutation({
			query: data => {
				const formData = new FormData();
				for (const key in data) {
					formData.append(key, data[key]);
				}
				return {
					url: `user/me`,
					method: "POST",
					body: formData
				};
			},
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			})
		}),
		changeMyPassword: build.mutation({
			query: data => ({
				url: `user/me/password`,
				method: "POST",
				body: data
			}),
			transformResponse: response => ({
				success: response.success,
				payload: response.payload
			})
		}),
		resetPassword: build.mutation({
			query: data => ({
				url: "auth/password/reset",
				method: "POST",
				body: data
			}),
			transformResponse: response => response.payload
		}),
		requestResetPassword: build.query({
			query: username => ({
				url: `auth/password/request/${username}`,
			}),
			transformResponse: response => response.payload
		}) 
	})
});

export const {
	useLoginMutation,
	useGetMeQuery,
	useLazyGetMeQuery,
	useLogoutQuery,
	useLazyLogoutQuery,
	useEditMeMutation,
	useChangeMyPasswordMutation,
	useResetPasswordMutation,
	useRequestResetPasswordQuery,
	useLazyRequestResetPasswordQuery
} = authAPI;

export default authAPI;
