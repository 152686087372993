import React from "react";

import FileSelector from "../FileSelector";
import { Button, Typography, Box } from "@mui/material";
import { getBackEndUrl, formatFilename } from "../../../general/helpers";

const InputLogo = ({
	formik,
	name,
	logoWidth = "255px",
	logoHeight = "75px",
	logoSize = "contain",
	accept = [".jpg", ".png", ".jpeg"],
	disabled,
	removeName,
	uploadName,
	label = "Space Logo",
	noLogoMsg = "no logo selected",
	btnLabels = {
		remove: "Remove",
		upload: "Upload"
	}
}) => {
	const getSrc = () => formik.values[name] && formik.values[name].url
			? getBackEndUrl(formik.values[name].url)
			: formik.values[name].constructor === File
				? URL.createObjectURL(formik.values[name])
				: null;

	const onRemoveLogo = () => {
		formik.setFieldValue(removeName, formik.values[name].id);
		formik.setFieldValue(name, null);
	};

	return (
		<FileSelector accept={accept}>
			{(onClick, file, clear) => {
				if (file && !formik.values[name]) {
					formik.setFieldValue(name, file);
					formik.setFieldValue(uploadName, file);
					clear();
				} else if (file && formik.values[name].size !== file.size) {
					formik.setFieldValue(name, file);
					formik.setFieldValue(uploadName, file);
					clear();
				}
				return (
					<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								width: "100%",
								mt: 2
							}}
						>
							<Box sx={{ width: logoWidth, height: logoHeight }}>
								{formik.values[name] ? (
									<Box
										sx={{
											backgroundSize: logoSize,
											width: "100%",
											height: "100%",
											backgroundRepeat: "no-repeat",
											backgroundImage: `url(${getSrc(formik.values[name].url)})`
										}}
									/>
								) : (
									<Box
										sx={{
											width: "100%",
											height: "100%",
											backgroundColor: "background.border",
											display: "flex",
											justifyContent: "center",
											alignItems: "center"
										}}
									>
										{noLogoMsg}
									</Box>
								)}
							</Box>
							<Box sx={{ height: "100%", ml: 2, display: "flex", flexDirection: "column", minWidth: "40%" }}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										mt: "auto",
										justifyContent: "center",
										alignItems: "flex-start"
									}}
									gap={0.5}
								>
									<Typography fontWeight={600}> {label}: </Typography>
									<Typography>
										{formik.values[name] ? formatFilename(formik.values[name].filename) : noLogoMsg}{" "}
									</Typography>
									<Typography>
										{formik.values[name] ? `${formik.values[name].size / 1024}kb` : "0kb"}
									</Typography>
								</Box>
								<Box
									gap={1}
									sx={{
										display: "flex",
										flexDirection: "row",
										alignSelf: "flex-end",
                                        mt: 1
									}}
								>
									<Button
										variant="contained"
										disabled={!formik.values[name] ? true : disabled}
										size="small"
										onClick={onRemoveLogo}
										color="primary"
									>
										{btnLabels.remove}
									</Button>
									<Button
										onClick={onClick}
										variant="contained"
										disabled={formik.values[name] ? true : disabled}
										size="small"
										color="primary"
									>
										{btnLabels.upload}
									</Button>
								</Box>
							</Box>
						</Box>
				);
			}}
		</FileSelector>
	);
};

export default InputLogo;
