import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";
import moment from "moment-timezone";

export const commandsAPI = createApi({
	reducerPath: "commands/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("control-cmd")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindCommands", "FindCommandsQueue"],
	endpoints: (build) => ({
		findCommands: build.query({
			query: () => ({
				url: "find",
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["FindCommands"],
		}),
		addCommand: build.mutation({
			query: (data) => ({
				url: "add",
				method: "POST",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindCommands"],
		}),
		editCommand: build.mutation({
			query: (data) => ({
				url: "edit",
				method: "PATCH",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindCommands"],
		}),
		removeCommand: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindCommands"],
		}),
		findCommandsQueue: build.query({
			query: ({ serial, status, limit, offset, search, sortItem, sortDir }) => ({
				url: `queue/find/${status}/${serial}`,
				params: {
					limit,
					offset,
					search,
					sortItem,
					sortDir
				}
			}),
			transformResponse: (response, meta, {timezone = "utc"}) => ({
				items: response?.payload?.items ? response.payload.items.map((item) => ({
					...item,
					commandName: (item?.cmd_name || "CMD_CONTROL_MACHINE").replaceAll("_", " ").replace("CMD", ""),
					description: (item?.cmd_name || "CMD_CONTROL_MACHINE"),
					created_at: moment(item?.created_at).tz(timezone).format("DD.MM.YYYY HH:mm:ss") || "-/-",
					sent_at: item.sent_at ? moment(item?.sent_at).tz(timezone).format("DD.MM.YYYY HH:mm:ss") : "-/-",
					got_reply_at: item.got_reply_at ? moment(item?.got_reply_at).tz(timezone).format("DD.MM.YYYY HH:mm:ss") : "-/-"
				})) : [],
				total: response.payload.total,
			}),
			providesTags: ["FindCommandsQueue"],
		}),
		addCommandToQueue: build.mutation({
			query: (data) => ({
				url: `queue/add/${data.serial}`,
				method: "POST",
				body: data,
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindCommandsQueue"],
		}),
		removeCommandFromQueue: build.mutation({
			query: (id) => ({
				url: `queue/cancel/${id}`,
				method: "DELETE",
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["FindCommandsQueue"],
		}),
	}),
});

export const {
	useFindCommandsQuery,
	useLazyFindCommandsQuery,
	useAddCommandMutation,
	useEditCommandMutation,
	useRemoveCommandMutation,
	useFindCommandsQueueQuery,
	useLazyFindCommandsQueueQuery,
	useAddCommandToQueueMutation,
	useRemoveCommandFromQueueMutation,
} = commandsAPI;

export default commandsAPI;
