import * as yup from "yup";
import {DEF_TABLE_COLUMN} from "../constants/constants";

const getFileInfo = (newFile) => new Promise ((resolved) => {
		const info = {};
		const i = new Image()
		i.onload = () => {
			info.width = i.width;
			info.height = i.height;
			info.name = newFile.name;
			info.size = newFile.size;
			resolved(info)
		};
		i.src = window.URL.createObjectURL(newFile);
	})

function ipv4(message = "Invalid IP address") {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
		message,
		excludeEmptyString: true
	}).test("ip", message, value => value === null || value === undefined || value.trim() === ""
			? true
			: value.split(".").find(i => parseInt(i, 10) > 255) === undefined);
}


function resolution(resolutions, message = "Invalid Resolution") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		async test(value) {
			if(!value) return true;
			try {
				const {width, height} = resolutions;
				const info = await getFileInfo(value);
				if(info.width !== width || info.height !== height){
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function ratioOneToOne(message = "Ratio should be 1:1") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		async test(value) {
			if(!value) return true;
			try {
				const info = await getFileInfo(value);
				if(info.width !== info.height){
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function size(fileSize = null, message = "Invalid size") {
	return this.test({
		name: "file",
		exclusive: false,
		message,
		test(value) {
			if(!value) return true;
			try {
				if(value.size > fileSize * 1000){
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function file(ext, message = "Invalid extensions") {
	return this.test({
		name: "file",
		exclusive: false,
		message: message || `Selected file ext should be one of ${ext.join(", ")}`,
		test(value) {
			if(!value) return true;
			try {
				const allowedRegExp = new RegExp(`(${ext.join("|")})$`, "i");
				if(!allowedRegExp.exec(value.name)){
					return false
				}
			} catch (error) {
				console.log(error);
			}
			return true;
		}
	});
}

function equalTo(ref, msg) {
	return this.test({
		name: "equalTo",
		exclusive: false,
		message: msg || "${path} must be the same as ${reference}",
		params: {
			reference: ref.path
		},
		test(value) {
			return value === this.resolve(ref);
		}
	});
}

const NULL_AND_NO_DEF = () => ``

function checkTableColumns(ref, msg) {
	return this.test({
		name: "checkTableColumns",
		exclusive: false,
		message: msg || "${path} must be the same as ${reference}",
		params: {
			reference: ref.path
		},
		test(value) {
			const {fields} = this.resolve(ref);
			const tableColumnsKeys = Object.keys(value.table_columns);
			for (const field of fields) {
				if(DEF_TABLE_COLUMN.includes(field.field_name)) continue;
				if(!tableColumnsKeys.includes(field.field_name)){
					if(!field.allowNull && !field.defaultValue) this.createError({ message: NULL_AND_NO_DEF() })
				}
			}
		}
	});
}

yup.addMethod(yup.mixed, "file", file);
yup.addMethod(yup.mixed, "ratioOneToOne", ratioOneToOne);
yup.addMethod(yup.mixed, "resolution", resolution);
yup.addMethod(yup.mixed, "size", size);

yup.addMethod(yup.object, "checkTableColumns", checkTableColumns);
yup.addMethod(yup.string, "equalTo", equalTo);
yup.addMethod(yup.string, "ipv4", ipv4);

export default yup;
