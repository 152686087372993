import React from "react";

/* MUI */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorPopup = (props) => {
	const {open, onClose: handleClose, content} = props;
	const {t} = useTranslation("main")
	return (
		<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{content?.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Typography> {content?.text} </Typography>
						{content?.hasList && (
							<ul>
								{content?.list.map(le => (
									<li>{le}</li>
								))}
							</ul>
						)}
						<Typography> {content?.sub} </Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary" autoFocus>
						{t("defaults.buttons.ok")}
					</Button>
				</DialogActions>
			</Dialog>
	);
};

export default ErrorPopup;
