import InputText from "./InputText";
import InputLogo from "./InputLogo";
import InputCheckBox from "./InputCheckBox";
import InputTextareaWithHint from "./InputTextareaWithHint";
import InputSpaceSelect from "./InputSpaceSelect";
import InputSelect from "./InputSelect";
import InputRadio from "./InputRadio";
import InputTimezone from "./InputTimezone";
import InputPassword from "./InputPassword";
import InputDateTime from "./InputDateTime";
import InputAutocomplete from "./InputAutocomplete";
import InputFile from "./InputFile";
import InputSwitch from "./InputSwitch";

import FormikForm from "./FormikForm";

export default FormikForm;

export {
    InputDateTime,
    InputAutocomplete,
	InputText,
	InputLogo,
	InputCheckBox,
	InputTextareaWithHint,
	InputSpaceSelect,
	InputSelect,
	InputRadio,
	InputTimezone,
	InputPassword,
	InputFile,
    InputSwitch
};
