/* eslint-disable */
import { createSlice, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

export const ALLOWED_DATA_TYPES = ["number", "string", "boolean"];
export const FORMS = [
	"mainInfo",
	"fields",
	"discard",
	"messages",
	"ui",
];
/**
 * @typedef Filter
 * @type {object}
 * @property {string} name
 * @property {object} write
 * @property {string} write.table
 * @property {string} uid
 */

/**
 * @typedef FilterData
 * @type {object}
 * @property {string} name
 * @property {String<"number"|"string"|"boolean">} type
 * @property {object} read
 * @property {boolean} new=false
 * @property {string} read.table
 * @property {string} read.column
 * @property {string} sensor_column
 * @property {array} discard
 * @property {array} messages
 * @property {string} uid
 * @property {string} related_filter
 */

/**
 * @typedef Unsaved
 * @type {object}
 * @property {string} uid
 * @property {String<"mainInfo"|"discard"|"messages"|"read_options"|"write_options"|"fields">} type
 */

/**
 * @type {object}
 * @property {string} name
 * @property {string} units
 * @property {String<"graph"|"bargraph"|"map">} graph
 */
const mainInfo = {
	name: "",
	units: "celsius",
	category: "graph",
	table: "",
	filter_type: null,
	type: "number",
	pos: 0,
};

/**
 * @type {Array<Filter>}

/** @type {Array<Unsaved>} */
const unsaved = [];

const initialState = {
	mainInfo,
	original: {},
	ui: {},
	fields: [],
	discard: {},
	messages: [],
	unsaved,
	toSave: {},
};

const oneSensorSlice = createSlice({
	name: "/dgp/sensors/oneSensor",
	initialState,
	reducers: {
		clear(state) {
			state = initialState;
		},
		setSensor(state, action) {
			const { payload } = action;
			state.original = payload;
			state.toSave = payload;
			const { fields = [], data, ui, ...sensorDesc } = payload;
			const { discard, messages } = data;
			const { read_table, raw_column, ...mainInfo } = sensorDesc;
			state.mainInfo = mainInfo;
			state.fields = (fields || []).map((f, i) => ({...f, seq: i, uid: uuidv4(),}));
			state.ui = ui;
			state.discard = discard;
			state.messages = messages.map((m, i) => ({...m, seq: i}));
		},
		onChange(state, action) {
			const { field, data } = action.payload;
			if (field in state) {
				state[field] = data;
			}
			if (!state.unsaved.includes(field)) {
				state.unsaved = [...state.unsaved, field];
			}
		},
		restore(state, action) {
			switch (action.payload) {
				case "mainInfo":
					const cloned = { ...state.original };
					state.mainInfo = _.omit(cloned, ["data", "write_table", "sensor_column", "raw_column", "read_table"]);
					break;
				case "fields":
					state[action.payload] = state.original.data[action.payload];
					break;
				case "discard":
				case "messages":
					state[action.payload] = state.original.data[action.payload];
					break;
				default:
					state[action.payload] = state.original[action.payload];
					break;
			}
			state.unsaved = [];
		},
		onEachSave(state, action) {
			switch (action.payload) {
				case "mainInfo":
					state.toSave = {
						...state.toSave,
						...state.mainInfo,
					};
					break;
				case "fields":
					state.toSave = {
						...state.toSave,
						fields: state[action.payload],
					};
					break;
				case "discard":
				case "messages":
					state.toSave = {
						...state.toSave,
						data: {
							...state.toSave.data,
							[action.payload]: state[action.payload],
						},
					};
					break;
				case "ui":
					state.toSave = {
						...state.toSave,
						ui: state[action.payload],
					};
					break;
				default:
					break;
			}
			state.unsaved = state.unsaved.filter((item) => item !== action.payload);
		},
	},
});

export const selectors = {
	makeFilterSelector: () =>
		createSelector(
			(state) => state.oneSensor,
			(__, uid) => uid,
			(sensor, uid) => sensor.filters.find((filter) => filter.uid === uid)
		),
	makeDataSelector: () =>
		createSelector(
			(state) => state.oneSensor,
			(__, uid) => uid,
			(sensor, uid) => sensor.filtersData.find((data) => data.uid === uid)
		),
};

export const { actions } = oneSensorSlice;
export default oneSensorSlice.reducer;
