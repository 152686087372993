/* Errors CONSTS */
export const SPACE_CONT_GROUPS = "SPACE_CONT_GROUPS";
export const SPACE_CONT_DEVICES = "SPACE_CONT_DEVICES";
export const SPACE_CONT_INVOICES = "SPACE_CONT_INVOICES";
export const SPACE_CONT_USERS = "SPACE_CONT_USERS";
export const GROUP_CONT_USERS = "GROUP_CONT_USERS";
export const GROUP_CONT_DEVICES = "GROUP_CONT_DEVICES";
export const USER_IN_GROUP = "USER_IN_GROUP";
export const MODEL_CONT_DEVICES = "MODEL_CONT_DEVICES";
export const TABLE_BELOGS_TO_SENSORS = "TABLE_BELOGS_TO_SENSORS";
export const SKU_BELOGS_TO_DEVICES = "SKU_BELOGS_TO_DEVICES";
export const SUBSYSTEM_BELONGS_TO_INTEGRATED_DEVICES = "SUBSYSTEM_BELONGS_TO_INTEGRATED_DEVICES";
export const INTEGRATED_DEVICE_BELOGS_TO_SKU = "INTEGRATED_DEVICE_BELOGS_TO_SKU";