import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const sensorsAPI = createApi({
	reducerPath: "sensors/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("sensors")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindSensors", "FindSensorGroups"],
	endpoints: (build) => ({
		findSensors: build.query({
			query: (params) => ({
				url: "find",
				params,
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["FindSensors"],
		}),
		findSensorGroups: build.query({
			query: (params) => ({
				url: "groups/find",
				params,
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["FindSensorGroups"],
		}),
		findOneSensorGroup: build.query({
			query: (id) => {
				if (!id) {
					throw new Error("ID is Required");
				}
				return {
					url: `groups/find/${id}`,
				};
			},
			transformResponse: (response) => response.payload,
		}),
		addSensorGroup: build.mutation({
			query: (data) => ({
				url: "groups/add",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: (response) => response.payload,
		}),
		editSensorGroup: build.mutation({
			query: (data) => ({
				url: "groups/save",
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: (response) => response.payload,
		}),
		removeSensorGroup: build.mutation({
			query: (id) => ({
				url: `groups/remove/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["FindSensors", "FindSensorGroups"],
			transformResponse: (response) => response.payload,
		}),
	}),
});

export const {
	useFindSensorsQuery,
	useLazyFindSensorsQuery,
	useFindSensorGroupsQuery,
	useLazyFindSensorGroupsQuery,
	useAddSensorGroupMutation,
	useEditSensorGroupMutation,
	useRemoveSensorGroupMutation,
	useFindOneSensorGroupQuery,
	useLazyFindOneSensorGroupQuery,
} = sensorsAPI;

export default sensorsAPI;
