import React from "react";
import { TableBody } from "@mui/material";
import ReactPlaceholder from "react-placeholder/lib";
import Item from "./Item";
import NoItems from "./NoItems";
import VirtualItem from "./VirtualItem";
import "react-placeholder/lib/reactPlaceholder.css";

const Body = (props) => {
    const {
        noItemsMsg,
        rows,
        columns,
        loading,
        pageSize,
        fixedHeight,
        searchQuery,
        selected,
        onSelect,
        disabled
    } = props;

	const generateList = (rowsData) => {
        let height = null;
        if (fixedHeight) {
            height = fixedHeight / pageSize;
        }
        const localItems = rowsData.map((item) => (
            <Item
                searchQuery={searchQuery}
                key={item.id}
                height={height}
                selected={selected}
                onSelect={onSelect}
                items={item}
                headers={columns}
                disabled={disabled}
                loading={loading}
            />
        ));

        if (localItems.length < pageSize) {
            const dif = pageSize - localItems.length;
            const add = Array(dif)
                .fill("")
                .map(() => <VirtualItem columns={columns} height={height} />);
            return [...localItems, ...add];
        }
        return localItems;
	};

    const getLoadingItems = (column, page) => {
        const loadingRows = []
        for (let index = 1; index <= page; index++) {
            const row = {}
            for (const colName of Object.keys(column)) {
                row[colName] = <ReactPlaceholder style={{minHeight: 16}} type="text" showLoadingAnimation ready={false} rows={1} color="#E0E0E0" />
            }
            loadingRows.push(row)
        }
        return loadingRows
    }

    return(
        <TableBody sx={{ position: "relative" }}>
            {!rows.length && !loading && <NoItems msg={noItemsMsg} />}
            {generateList(loading ? getLoadingItems(columns, pageSize) : rows)}
        </TableBody>
    );
}

export default Body;