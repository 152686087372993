
import React, { useRef, useState, useEffect, useContext } from "react";
import { tableContext } from "./selectable-table-provider";

/* MUI */
import { Box, Button, IconButton, Select, Table, MenuItem, useMediaQuery } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

/* @components */
import SmallPagination from "../SmallPagination";
import SearchLine from "../SearchLine";

import { useTranslation } from "react-i18next";

import Header from "./Header";
import Body from "./Body";
import Csvs from "./Csvs";

const useRefState = (val) => {
	const [myState, _setMyState] = useState(val);
	const myStateRef = React.useRef(myState);
	const setMyState = (data) => {
		myStateRef.current = data;
		_setMyState(data);
	};
	return [myState, setMyState];
};

export const SelectableTabel = (props) => {
	const { t } = useTranslation("main");
	/* props */
	const {
		/* Methods */
		onRemove,
		onAdd,
		onSelect: handleSelect = () => {},
		onSearch,
		nextPage,
		prevPage,
		onSort,
		onPageSizeChange = () => {},
		useRefreshBtn = false,
		onRefresh = () => {},
		/* /Methods */
		sx = {},
		noItemsMsg = undefined,
		selected = null,
		page = 0,
		currentPage = 1,
		useTools = true,
		total = 0,
		totalPages,
		perPage = [5, 10, 20, 50],
		rows,
		columns,
		loading,
		csvs,
		disabled,
		disablePagination = false,
		pageSize,
		sort = false,
		allowPageSizeChange = false,
		searchNotIn = [],
		appendToTools = [],
		prependToTools = [],
		allowHighResOverflow = false,
		labels = {
			add: t("defaults.table.btns.add"),
			remove: t("defaults.table.btns.remove"),
			found: t("defaults.table.labels.found"),
			search: t("defaults.table.labels.search"),
			of: t("defaults.table.labels.of"),
		},
		fixedHeight = false,
		mini = false,
	} = props;

	/* refs */
	const containerRef = useRef(null);

	/* state */
	const [focused, setFocused] = useState(false);
	const [hotKeyCMD, setHotKeyCMD] = useRefState(""); // nextPage || prevPage || nextElement || prevElemet || lastPage || firstPage
	const [searchQuery, setSearchQuery] = useState(null);
	const [lastActions, setLastAction] = useState(null);
	const isDownSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const onSelect = (value) => {
		if (!disabled) {
			handleSelect(value, rows);
		}
	};

	useEffect(() => {
		if (lastActions === "nextPage.nextElement") {
			onSelect(rows[0].id, rows);
		} else if (lastActions === "prevPage.prevElement") {
			onSelect(rows[rows.length - 1].id, rows);
		}
	}, [rows]);

	const nextElement = () => {
		setLastAction(null);
		let activeIndex = null;
		const nowEl = rows.filter((r, i) => { //eslint-disable-line
			if (r.id === selected) {
				activeIndex = i;
				return r;
			}
		});

		if (!selected) {
			onSelect(rows[0].id);
			return;
		}

		if (rows[activeIndex + 1]) {
			onSelect(rows[activeIndex + 1].id);
		} else if (!rows[activeIndex + 1] && currentPage !== totalPages) {
			nextPage("next");
			setLastAction("nextPage.nextElement");
		}
	};

	const prevElement = () => {
		setLastAction(null);
		let activeIndex = null;
		const nowEl = rows.filter((r, i) => { //eslint-disable-line
			if (r.id === selected) {
				activeIndex = i;
				return r;
			}
		});

		if (rows[activeIndex - 1]) {
			onSelect(rows[activeIndex - 1].id);
		} else if (!rows[activeIndex - 1] && currentPage !== 1) {
			prevPage("prev");
			setLastAction("prevPage.prevElement");
		}
	};

	useEffect(() => {
		if (focused && !disabled) {
			if (hotKeyCMD.startsWith("nextPage")) {
				if (currentPage !== totalPages) nextPage("next");
			} else if (hotKeyCMD.startsWith("prevPage")) {
				if (currentPage !== 1) prevPage("prev");
			} else if (hotKeyCMD.startsWith("nextElement")) {
				nextElement();
			} else if (hotKeyCMD.startsWith("prevElement")) {
				prevElement();
			}
		}
	}, [hotKeyCMD]);

	const onPageChange = (der) => {
		if (der === "next") nextPage(der);
		else prevPage(der);
	};

	useEffect(() => {
		document.addEventListener("click", () => setFocused(false));
	}, []);

	const onTableFocus = (e) => {
		e.stopPropagation();
		document.dispatchEvent(new Event("click"));
		setFocused(true);
	};

	const handleKey = (event) => {
		/*
		 * PageDown = 34 -last page
		 * PageUp = 33 - first page
		 *
		 * ArrowUp = 38 - next item
		 * ArrowDown = 40 - prev item
		 *
		 * ArrowLeft = 37 - prev page
		 * ArrowRight = 39 - next page
		 *
		 * Enter = 13 - confirm
		 *
		 *
		 *  */
		const PageDownKey = 34;
		const PageUpKey = 33;
		const ArrowUpKey = 38;
		const ArrowDownKey = 40;
		const ArrowLeftKey = 37;
		const ArrowRightKey = 39;
		const EnterKey = 13;

		const { keyCode } = event;

		if ([PageDownKey, PageUpKey, ArrowUpKey, ArrowDownKey, ArrowLeftKey, ArrowRightKey, EnterKey].includes(keyCode)) {
			event.preventDefault();
		}
		if (keyCode === PageDownKey) {
			setHotKeyCMD(`lastPage_${new Date().getTime()}`);
		} else if (keyCode === PageUpKey) {
			setHotKeyCMD(`firstPage_${new Date().getTime()}`);
		} else if (keyCode === ArrowRightKey) {
			setHotKeyCMD(`nextPage_${new Date().getTime()}`);
		} else if (keyCode === ArrowLeftKey) {
			setHotKeyCMD(`prevPage_${new Date().getTime()}`);
		} else if (keyCode === ArrowUpKey) {
			setHotKeyCMD(`prevElement_${new Date().getTime()}`);
		} else if (keyCode === ArrowDownKey) {
			setHotKeyCMD(`nextElement_${new Date().getTime()}`);
		}
	};

	useEffect(() => {
		if (focused) {
			document.addEventListener("keydown", handleKey);
		} else {
			document.removeEventListener("keydown", handleKey);
		}
		return () => {
			document.removeEventListener("keydown", handleKey);
		};
	}, [focused]);

	const handleSearch = (val) => {
		onSearch(val);
		setSearchQuery(val);
	};

	return (
		<Box ref={containerRef} sx={{ width: "100%", overflow: "none" }}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					borderBottom: "3px solid",
					borderBottomColor: focused && !disabled ? "primary.main" : "background.border",
					overflow: "none",
					cursor: disabled ? "default" : "pointer",
				}}
			>
				<SearchLine
					disabled={disabled}
					border={focused}
					found={total}
					label={labels.search}
					foundLabel={labels.found}
					onChange={(e) => handleSearch(e.target.value)}
				/>
				{useRefreshBtn && (
					<Box
						sx={{
							ml: "auto",
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-start",
							justifyContent: "flex-end",
						}}
					>
						<IconButton title="Refresh table" component="button" onClick={onRefresh}>
							<RefreshIcon />
						</IconButton>
					</Box>
				)}
				{csvs && <Csvs columns={columns} rows={rows} />}
			</Box>
			<Box
				sx={{
					position: "relative",
					border: "1px solid #3d3d3d",
					width: "100%",
					minWidth: "100%",
					maxWidth: "100%",
					overflowX: isDownSm || allowHighResOverflow ? "scroll" : "clip",
				}}
				onClick={onTableFocus}
			>
				<Table
					sx={{
						width: "100%",
						tableLayout: isDownSm && "auto",
						whiteSpace: isDownSm && "nowrap",
						overflowX: isDownSm ? "auto" : "hidden",
						...sx,
					}}
				>
					<Header
						disabled={rows.length ? disabled : true}
						columns={columns}
						sort={sort}
						searchNotIn={searchNotIn}
						onSort={onSort}
						mini={mini}
					/>
					<Body
						noItemsMsg={noItemsMsg}
						rows={rows}
						columns={columns}
						loading={loading}
						pageSize={pageSize}
						fixedHeight={fixedHeight}
						searchQuery={searchQuery}
						selected={selected}
						onSelect={onSelect}
						disabled={disabled}
					/>
				</Table>
			</Box>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "flex-start",
					flexWrap: "wrap",
				}}
			>
				<Box sx={{ mt: 2 }}>
					<SmallPagination
						disabled={disablePagination ? true : rows.length ? disabled : true}
						current={currentPage}
						page={page}
						onChange={onPageChange}
						total={totalPages || Math.ceil(total / pageSize)}
						ofLabel={labels.of}
					/>
				</Box>
				{prependToTools.length ? <Box sx={{ mt: 2 }}>{prependToTools}</Box> : ""}
				{useTools ? (
					<Box gap={2} sx={{ display: "flex", flexDirection: "row", mt: 2, justifyContent: "flex-end", width: "max-content" }}>
						<Button variant="contained" size="small" disabled={!selected} onClick={onRemove} color="primary">
							{labels.remove}
						</Button>
						<Button variant="contained" size="small" onClick={onAdd} color="primary">
							{labels.add}
						</Button>
					</Box>
				) : (
					""
				)}
				{allowPageSizeChange && Array.isArray(perPage) ? (
					<Select
						value={pageSize}
						sx={{ ml: "auto", mt: 2 }}
						variant="outlined"
						size="small"
						onChange={(e) => onPageSizeChange(e.target.value)}
					>
						{perPage.map((p) => (
							<MenuItem key={p} value={p}>
								{p}
							</MenuItem>
						))}
					</Select>
				) : (
					""
				)}
				{appendToTools.length ? <Box sx={{ mt: 2 }}>{appendToTools}</Box> : ""}
			</Box>
		</Box>
	);
};

const Wrapper = (props) => {
	const tableDataContext = useContext(tableContext);
	return <SelectableTabel {...{ ...props, ...tableDataContext }} />;
};

export default Wrapper;
