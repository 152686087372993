import React, {useEffect, useState} from "react";

/* Components */
import ErrorPopup from "./ErrorPopup";

/* Redux */
import {useSelector, useDispatch} from "react-redux"
import {markEventAsShown} from "@redux/app/slice";

/* Hooks */
import { useAppEvents } from "general/hooks";

/* Utils */
import { toast } from "react-hot-toast";
import gen from "./generateContent";

const AppEvents = () => {
    const dispatch = useDispatch();
	const {formatMessage} = useAppEvents()
    const [errorPopupOpen, setErrorPopupOpen] = useState(false);
	const [errorContent, setErrorContent] = useState(null)
	const eventList = useSelector(state => state.app.eventList);

    const handleNotification = (event) => {
        if(event.severity === "error"){
            if(event.name === "multiple"){
                for (const msg of event.list) {
                    toast.error(formatMessage(msg))
                }
            } else {
                toast.error(formatMessage(event.message))
            }
        } else if(event.severity === "success"){
            toast.success(formatMessage(event.message))
        }
        dispatch(markEventAsShown(event.uuid))
    }

    const handlePopup = (event) => {
        setErrorContent({list: event?.list || [], ...gen(event.name)})
        setErrorPopupOpen(true)
        dispatch(markEventAsShown(event.uuid))
    }

    const handleErrorPopupClose = () => {
        setErrorPopupOpen(false)
        setErrorContent(null)
    }

    useEffect(() => {
		const events = eventList.filter(event => !event.shown);
		for (const event of events) {
            switch (event.type) {
                case "notification":
                    handleNotification(event)
                    break;
                case "popup":
                    handlePopup(event)
                    break;
                default:
                    break;
            }
		}
	}, [eventList])

    return(<ErrorPopup content={errorContent} open={errorPopupOpen} onClose={handleErrorPopupClose}/>);
}

export default AppEvents;