import { createApi, fetchBaseQuery, } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

import { getRoleLabel } from "@helpers";

export const usersAPI = createApi({
	reducerPath: "users/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("users")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindUsers"],
	endpoints: build => ({
		findUsers: build.query({
			query: ({groupId, spaceId, ...params}) => ({
				url: "find",
				params: {
					...params,
					group_id: groupId,
					space_id: spaceId
				}
			}),
			transformResponse: (response) => ({
				items: response.payload.items.map((item => ({
					...item,
					id: item.id,
					email: item.email,
					space: item.space,
					fullname: `${item?.firstname} ${item?.lastname}`,
					role_label: getRoleLabel(item.role),
                }))),
				total: response.payload.total,
			}),
			providesTags: ["FindUsers"]
		}),
		addUser: build.mutation({
			query: data => ({
				url: "add",
				method: "POST",
				body: data
			}),
			invalidatesTags: ["FindUsers"],
			transformResponse: response => response.payload
		}),
		editUser: build.mutation({
			query: data => ({
				url: "save",
				method: "PATCH",
				body: data
			}),
			invalidatesTags: ["FindUsers"],
			transformResponse: response => response.payload
		}),
		removeUser: build.mutation({
			query: (id) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			invalidatesTags: ["FindUsers"],
			transformResponse: response => response.payload
		}),
		resendWelcome: build.query({
			query: id => ({
				url: `resend/${id}`
			}),
			transformResponse: response => response.payload
		})
	})
});

export const {
	useFindUsersQuery,
	useLazyFindUsersQuery,
	useAddUserMutation,
	useEditUserMutation,
	useRemoveUserMutation,
	useResendWelcomeQuery,
	useLazyResendWelcomeQuery
} = usersAPI;

export default usersAPI;