
/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { setToStorage, getFromStorage as get } from "general/storage";
import Cookies from "js-cookie";
import _ from "lodash";
import {v4 as uuidV4} from "uuid"

/* Initial State & State structure */
const initialState = {
	defs: {
		pages: {
			admin: {
				space_msg: "",
				group_msg: "",
				device_keys: { value: '{"payload_key":"","payload_iv":"","chaskey_key":"","regpin":"","userpin":""}' }
			}
		}
	},
	models: [],
	storage: {},
	isInitialized: false,
	isLoading: false,
	eventList: [],
	mode: get("app.mode", "light") 
};

const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setMode(state, action) {
			state.mode = action.payload
			setToStorage("app.mode", action.payload)
		},
		setDefs(state, action) {
			state.defs = action.payload;
		},
		setModels(state, action) {
			state.models = action.payload;
		},
		setInitialized(state, action) {
			state.isInitialized = action.payload
		},
		setLoading(state, action) {
			state.isLoading = action.payload;
		},
		setStorageAC(state, action) {
			state.storage = action.payload;
		},
		addToStorageAC(state, action) {
			return {
                ...state,
                storage: {
                    ...state.storage,
                    [action.payload.path]: {
                        ...state.storage[action.payload.path],
                        [action.payload.name]: action.payload.value
                    }
                }
            }
		},
		clearAll(state) {
			Object.keys(Cookies.get()).forEach((cookieName) => {
				const neededAttributes = {};
				Cookies.remove(cookieName, neededAttributes);
			});
			return {
				...state,
				storage: {}
			};
		},
		addEvent(state, action){
			state.eventList.push({uuid: uuidV4(), shown: false, ...action.payload})
		},
		markEventAsShown(state, action){
			state.eventList = state.eventList.map(event => {
				if(event.uuid === action.payload){
					const e = event
					e.shown = true;
					return e
				}
				return event;
			})
		}
	}
});


export const { setDefs, setStorageAC, setLoading, setModels, clearAll, addToStorageAC, addEvent, markEventAsShown, setMode } = appSlice.actions;

export const setStorage = () => (dispatch, getState) => {
	const all = Cookies.getJSON();
	let payload = {};
	const { storage } = getState().app;
	if (typeof all === "object" && !_.isEqual(storage, all)) {
		payload = { ...all };
	}
	dispatch(setStorageAC(payload));
};

export const addToStorage = (name, value) => (dispatch) => {
	let path = window.location.pathname.replace("/", "");
	if (name.indexOf(".") >= 0) {
		[path, name] = name.split(".");
	}

	const exist = Cookies.getJSON(path);

	if (typeof exist === "object") {
		Cookies.set(path, { ...exist, [name]: value }, { expires: 7, path: "" });
	} else {
		Cookies.set(path, { [name]: value }, { expires: 7, path: "" });
	}

	const storageData = {
		path,
		name,
		value
	};
	dispatch(addToStorageAC(storageData));
};

export const getFromStorage = (name, def = null) => (dispatch, getState) => {
	const { storage } = getState().app;
	let path = window.location.pathname.replace("/", "");
	if (name.indexOf(".") >= 0) {
		[path, name] = name.split(".");
	}
	if (path in storage) {
		if (name in storage[path]) return storage[path][name];
		return def;
	} return def;
};

export default appSlice.reducer
