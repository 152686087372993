import React, { useState, useEffect } from "react";

/* MUI */
import { AppBar, Toolbar, IconButton, Typography, useMediaQuery, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";

/* Assets */
import logo from "../../assets/images/full-logo.png";
import acrotrace from "../../assets/svg/AcroTrace_logo.svg";

/* Redux */
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

/* Components */
import SpaceLogo from "@components/common/SpaceLogo";
import ButtonLink from "../shared/ui/ButtonLink";
import AppDrawer from "./AppDrawer";
import UserProfile from "./UserProfile";
import RoundButton from "../shared/ui/RoundButton";
import Info from "./Info";
import ProtectedComponent from "../../authentication/components/protected";

/* Utils */
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		color: "#4A4A4A",
		boxShadow: "0px 1px 5px 0 rgba(0, 0, 0, 0.5)",
		backgroundColor: theme.palette.background.paper,
	},
	toolBar: {
		height: "65px !important",
		minHeight: "65px !important",
		width: "100%",
		margin: "0 auto",
		position: "relative",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	toolBarScrolled: {
		height: 85,
	},
	flex: {
		flex: 1,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		display: "flex",
		overflow: "hidden",
	},
	flexLogo: {
		cursor: "pointer",
		height: 30,
		flex: 1,
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
		justifyContent: "center",
		display: "flex",
		zIndex: "2",
	},
	acrotrace: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		zIndex: "1",
	},
	wrap: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		zIndex: "2",
	},
	logo: {
		display: "flex",
		alignItems: "center",
		letterSpacing: "2px",
		cursor: "pointer",
		marginLeft: "50%",
	},
	logoIcon: {
		width: 24,
		marginRight: 10,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	drawer: {
		[theme.breakpoints.up("lg")]: {
			width: 250,
		},
	},
	login: {
		marginLeft: 20,
		height: 30,
		widht: 85,
		borderRadius: 20,
	},
	loginLink: {
		textDecoration: "none",
	},
	loginLinkText: {
		color: theme.palette.common.white,
	},
	headerTitle: {
		color: "text.secondary",
		borderBottom: "3px solid rgba(255, 255, 255, 0)",
	},
	selected: {
		borderBottom: `3px solid ${theme.palette.primary.main}`,
	},
	loginLinkTest: {
		fontSize: 11,
		color: "white",
	},
	imgLogo: { height: "52px", cursor: "pointer", paddingLeft: 16 },
	imgLogoScrolled: { height: 44 },
}));

const AppMenu = (props) => {
	const { user } = props;
	const history = useHistory();
	const location = useLocation()
	const classes = useStyles();
	const spaceLogo = useSelector((state) => state.auth.user && state.auth.user.space.logo);
	const { t } = useTranslation("main");
	const [mobileOpen, setMobileOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false);
	const [selected, setSelected] = useState("");

	const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
	const isDownMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const handleScroll = () => {
		if (window.pageYOffset === 0) {
			setScrolled(false);
		} else {
			setScrolled(true);
		}
		// do something like call `setScrolled`
		// access window.scrollY etc
	};

	const handleDrawerOpen = () => {
		setMobileOpen(true);
		document.getElementById("root").style.filter = "blur(8px)";
	};

	const handleDrawerClose = () => {
		setMobileOpen(false);
		document.getElementById("root").style.filter = "";
	};

	const goToHome = () => {
		history.push("/dashboard");
	};

	const onItemClick = (selectedItem) => () => {
		setSelected(selectedItem);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });
		setSelected(location.pathname.replace("/", ""));
	}, [location]);

	useEffect(() => {
		const path = location.pathname.replace("/", "");
		if (selected !== path) {
			setSelected(path);
		}
	}, [selected]);

	useEffect(() => window.removeEventListener("scroll", handleScroll), []);

	return (
		<Box className={classes.root}>
			<AppBar position="fixed" sx={{ backgroundColor: "background.paper", color: "primary.main" }} elevation={12}>
				<Toolbar className={scrolled ? classNames(classes.toolBar, classes.toolBarScrolled) : classes.toolBar}>
					<Box className={classes.flex} style={{ maxWidth: 207, zIndex: 2 }}>
						{spaceLogo ? (
							<SpaceLogo logo={spaceLogo} onClick={goToHome} />
						) : (
							<Box onClick={goToHome}>
								<img
									className={
										scrolled ? classNames(classes.imgLogo, classes.imgLogoScrolled) : classes.imgLogo
									}
									src={logo}
									alt="Logo"
								/>
							</Box>
						)}
					</Box>
					{isUpMd && (
						<Box className={classNames(classes.flex, classes.acrotrace)}>
							<Box sx={{ width: 160 }}>
								<ReactSVG
									src={acrotrace}
									onClick={() => {
										window.location.href = "https://acromove.com";
									}}
									className={classes.flexLogo}
								/>
							</Box>
						</Box>
					)}
					<Box className={classes.wrap}>
						{user ? (
							<>
								{isUpMd && (
									<>
										<ProtectedComponent rule="pages:dashboard_view">
											<ButtonLink
												onClick={onItemClick("dashboard")}
												typoClass={classNames(
													classes.headerTitle,
													selected === "dashboard" ? classes.selected : ""
												)}
												path="/dashboard"
												translationKey="header.dashboard"
												variant="body2"
												color="text.primary"
											/>
										</ProtectedComponent>
										<ProtectedComponent rule="pages:tracker_view">
											<ButtonLink
												onClick={onItemClick("tracker")}
												typoClass={classNames(
													classes.headerTitle,
													selected === "tracker" ? classes.selected : ""
												)}
												path="/tracker"
												translationKey="header.tracker"
												variant="body2"
												color="text.primary"
											/>
										</ProtectedComponent>
										<ProtectedComponent rule="pages:superadmin_view">
											<ButtonLink
												onClick={onItemClick("superadmin")}
												typoClass={classNames(
													classes.headerTitle,
													selected === "superadmin" ? classes.selected : ""
												)}
												path="/superadmin?dashboard"
												translationKey="header.admin"
												variant="body2"
												color="text.primary"
											/>
										</ProtectedComponent>
										<ProtectedComponent rule="pages:admin_view">
											<ButtonLink
												onClick={onItemClick("admin")}
												typoClass={classNames(
													classes.headerTitle,
													selected === "admin" ? classes.selected : ""
												)}
												path="/admin"
												translationKey="header.admin"
												variant="body2"
												color="text.primary"
											/>
										</ProtectedComponent>
										<ProtectedComponent rule="pages:manager_view">
											<ButtonLink
												onClick={onItemClick("manager")}
												typoClass={classNames(
													classes.headerTitle,
													selected === "manager" ? classes.selected : ""
												)}
												path="/manager"
												translationKey="header.manager"
												variant="body2"
												color="text.primary"
											/>
										</ProtectedComponent>
									</>
								)}
								<UserProfile user={user} history={history} t={t} />
							</>
						) : (
							[
								<RoundButton
									key={1}
									type="button"
									className={classNames(classes.login, "login_btn")}
									sx={{ mr: 1 }}
								>
									<Link to="/login" className={classes.loginLink}>
										<Typography variant="subtitle1" className={classes.loginLinkTest}>
											{t("header.login").toUpperCase()}
										</Typography>
									</Link>
								</RoundButton>,
							]
						)}
						{isUpMd && <Info history={history} t={t} />}
						{isDownMd && (
							<IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
								<MenuIcon />
							</IconButton>
						)}
					</Box>
				</Toolbar>
			</AppBar>
			<AppDrawer
				className={classes.drawer}
				onClose={handleDrawerClose}
				onOpen={handleDrawerOpen}
				mobileOpen={mobileOpen}
				history={history}
				user={user}
			/>
		</Box>
	);
};

export default AppMenu;
