import React from "react";
import { Box } from "@mui/material";

const NoItems = props => {
	const { msg = "No items" } = props;
	return (
		<Box
			sx={{
				position: "absolute",
				width: "100%",
				height: "100%",
				backgroundColor: "background.darkGray",
				zIndex: 10,
				top: 0,
				left: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "background.paper",
                fontSize: "2.5em"
			}}
		>
			{msg}
		</Box>
	);
};

export default NoItems;
