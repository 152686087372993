import React, { useState, useEffect } from "react";

/* Material UI */
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const InputPassword = ({
	formik,
	labels = {
		password: "Password",
		confirm: "Confirm Password"
	},
	name,
	size = "small",
	confirmName,
	helperText,
	disabled,
	variant = "outlined",
	passwordOnly = false,
	sx = {}
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfPassword, setShowConfPassword] = useState(false);

	useEffect(
		() => {
			if (disabled) {
				setShowPassword(false);
				setShowConfPassword(false);
			}
		},
		[disabled]
	);

	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
				<TextField
					size={size}
					sx={{ width: "100%", ...sx }}
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword(!showPassword)}
									disabled={disabled}
								>
									{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
								</IconButton>
							</InputAdornment>
						)
					}}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched[name] && formik.errors[name]}
					disabled={disabled}
					name={name}
					value={formik.values[name]}
					label={labels.password}
					variant={variant}
					helperText={helperText || formik.errors[name]}
				/>
			</Box>
			{!passwordOnly && (
				<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
					<TextField
						size={size}
						type={showConfPassword ? "text" : "password"}
						sx={{ width: "100%", ...sx }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowConfPassword(!showConfPassword)}
										disabled={disabled}
									>
										{showConfPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</InputAdornment>
							)
						}}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched[confirmName] && formik.errors[confirmName]}
						disabled={disabled}
						name={confirmName}
						value={formik.values[confirmName]}
						label={labels.confirm}
						variant={variant}
						helperText={helperText || formik.errors[confirmName]}
					/>
				</Box>
			)}
		</>
	);
};

export default InputPassword;
