import React from "react";

/* MUI */
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";

const InputTimezone = (props) => {
    const { name, formik, disabled, placeholder = "Select Timezone...", label = "Timezone", size = "small" } = props

	return (
		<Autocomplete
            sx={{width: "100%"}}
            onChange={(_, e) => {
                formik.setFieldValue(name, e.value)
            }}
            placeholder={placeholder}
            name={name}
            value={formik?.values[name]}
			disablePortal
            disabled={disabled}
			options={moment.tz.names().map(tz => ({label: tz, value: tz}))}
			renderInput={(params) => <TextField {...params} label={label} size={size} />}
		/>
	);
};


export default InputTimezone;

