import React from "react";

/* Material UI */
import { Box, Button, Typography } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

const SmallPagination = (props) => {
	const { onChange, current, total, disabled, ofLabel = "of" } = props;
	return (
		<Box gap={1} sx={{ display: "flex", flexDirection: "row" }}>
			<Button
				size="small"
				disabled={current === 1 || disabled}
				onClick={() => {
					onChange("prev");
				}}
				variant="contained"
				sx={{ minWidth: "20px" }}
				color="primary"
			>
				<ArrowLeft />
			</Button>
			<Typography>
				{current} {ofLabel} {total}
			</Typography>
			<Button
				size="small"
				disabled={current === (total || 1) || disabled}
				onClick={() => {
					onChange("next");
				}}
				variant="contained"
				sx={{ minWidth: "20px" }}
				color="primary"
			>
				<ArrowRight />
			</Button>
		</Box>
	);
};

export default SmallPagination;
