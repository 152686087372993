const typeCast = (type, value) => {
	switch (type) {
		case "number":
			return Number(value);
		case "boolean":
			return value === "true";
		case "object":
			return JSON.parse(value);
		case "string":
		default:
			return value;
	}
};

export const setToStorage = (path, value) => {
	const type = typeof value;
	const val = type === "object" ? JSON.stringify(value) : value;
	localStorage.setItem(path, val);
	localStorage.setItem(`${path}.type`, type);
	return value;
};

export const getFromStorage = (path, defaultValue) => {
	const val = localStorage.getItem(path);
	const type = localStorage.getItem(`${path}.type`);
	const castedValue = typeCast(type, val);
	return castedValue === null || undefined ? defaultValue : castedValue;
};
