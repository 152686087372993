import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";
import { ONE_HOUR } from "constants/constants";
import i18n from "../../i18n";
import moment from "moment";

export const securityListAPI = createApi({
	reducerPath: "securityList/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("security-list")),
	keepUnusedDataFor: 30,
	tagTypes: ["FindBlockedSecurityList", "FindPendingSecurityList"],
	endpoints: build => ({
		findBlocked: build.query({
			query: params => ({
				url: "blocked/find",
				params
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items.map(row => {
					const till = row?.is_blocked
						? moment(row.blocked_till)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss")
						: moment(Date.parse(row.first_attempt) + ONE_HOUR)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss");
					const at = row?.blocked_at
						? moment(row.blocked_at)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss")
						: i18n?.t(`admin_security_list_page:blocked_tab.table.not_blocked`);

					const result = {
						...row,
						msg: row.reasons.map(reason => `${reason.attempts} ${reason.msg}`).join(", "),
						till,
						blocked_till: row.is_blocked ? row.blocked_till : Date.parse(row.first_attempt) + ONE_HOUR,
						reasons: row.reasons.map(reason => reason.type),
						reason: "servers_multiply",
						ip_label: row?.ip && row?.port ? `${row.ip}:${row.port}` :  i18n?.t(`admin_security_list_page:blocked_tab.table.unknown_ip`),
						dgpid_label: row?.dgpid || i18n?.t(`admin_security_list_page:blocked_tab.table.unknown_dgpid`),
						at
					}
					return result;
				})
			}),
			providesTags: ["FindBlockedSecurityList"]
		}),
		findPending: build.query({
			query: params => ({
				url: "pending/find",
				params
			}),
			transformResponse: response => ({
				total: response.payload.total,
				items: response.payload.items.map(row => {
					const till = row?.is_blocked
						? moment(row.blocked_till)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss")
						: moment(Date.parse(row.first_attempt) + ONE_HOUR)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss");
					const at = row?.blocked_at
						? moment(row.blocked_at)
								.utc()
								.format("DD.MM.YYYY HH:mm:ss")
						: i18n?.t(`admin_security_list_page:pending_tab.table.not_blocked`);

					const result = {
						...row,
						msg: row.reasons.map(reason => `${reason.attempts} ${reason.msg}`).join(", "),
						till,
						blocked_till: row.is_blocked ? row.blocked_till : Date.parse(row.first_attempt) + ONE_HOUR,
						reasons: row.reasons.map(reason => reason.type),
						reason: "servers_multiply",
						ip_label: row?.ip && row?.port ? `${row.ip}:${row.port}` : i18n?.t(`admin_security_list_page:pending_tab.table.unknown_ip`),
						dgpid_label: row?.dgpid || i18n?.t(`admin_security_list_page:pending_tab.table.unknown_dgpid`),
						at
					}
					return result;
				})
			}),
			providesTags: ["FindPendingSecurityList"]
		}),
		addToSecurityList: build.mutation({
			query: data => ({
				url: "add",
				method: "POST",
				body: data
			}),
			invalidatesTags: ["FindBlockedSecurityList", "FindPendingSecurityList"],
			transformResponse: response => response.payload
		}),
		editSecurityList: build.mutation({
			query: data => ({
				url: "save",
				method: "PATCH",
				body: data
			}),
			invalidatesTags: ["FindBlockedSecurityList", "FindPendingSecurityList"],
			transformResponse: response => response.payload
		}),
		removeFromSecurityList: build.mutation({
			query: ({ id }) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			invalidatesTags: ["FindBlockedSecurityList", "FindPendingSecurityList"],
			transformResponse: response => response.payload
		})
	})
});

export const {
	useFindBlockedQuery,
	useLazyFindBlockedQuery,
	useFindPendingQuery,
	useLazyFindPendingQuery,
	useAddToSecurityListMutation,
	useEditSecurityListMutation,
	useRemoveFromSecurityListMutation
} = securityListAPI;

export default securityListAPI;
