import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addEvent } from "@redux/app/slice";
import { useTranslation } from "react-i18next";
import useAuth from "authentication/hooks/use-auth";
import { useHistory, useLocation } from "react-router";

export const useLocalUserSettings = (name, initialValue, options = {}) => {
    const {includeLocation = true} = options;
    const auth = useAuth();
    const location = useLocation()
    
    const getSlug = () => `${auth.user.username}_${name.replaceAll(" ", "_")}${includeLocation ? location.pathname.replaceAll("/", "__") : ""}`
    
    const getInitialValue = () => {
        const val = localStorage.getItem(getSlug());
        if(val){
            return JSON.parse(val).value
        } 
        localStorage.setItem(getSlug(), JSON.stringify({value: initialValue}));
        return initialValue
    }
    
    const [value, setValue] = useState(getInitialValue);
    const set = (newValue) => {
        setValue(newValue)
        localStorage.setItem(getSlug(), JSON.stringify({value: newValue}));
    }
    
    return [value, set]
}

export const useInitialRedirect = (page) => {
	const location = useLocation();
	const history = useHistory();
	const { user, isAllowed } = useAuth();

    const tool = location.search.substring(1, location.search.length) || "dashboard";

    const redirect = () => {
        if (!user) {
			history.push("/login");
		}
		if (isAllowed(`pages:${`${page}_${tool.replace("-", "_")}_view`}`)) {
			return history.push(`/${page}?${tool}`)
		}
		return history.push(`/${page}?dashboard`);
    }

	useEffect(() => {
        redirect()
	}, []);

    return tool
};

export const useDebounce = (value, delay = 300) => {
	const [debounced, setDebounced] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => setDebounced(value), delay);
		return () => clearTimeout(handler);
	}, [value, delay]);

	return debounced;
};

export const useAppDispatch = (action) => {
	const dispatch = useDispatch();
	return useCallback((payload) => dispatch(action(payload)), [dispatch]);
};

export const useAppEvents = (i18nNS) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation(i18nNS);

	const formatMessage = (message) => {
		if (message) {
			if (message.startsWith("t:")) {
				const msg = message.slice(2);
				if (msg.includes(":")) {
					return i18n.t(msg);
				}
				return t(msg);
			}
			return message;
		}
		return null;
	};

	const onError = (message) => {
		dispatch(
			addEvent({
				severity: "error",
				type: "notification",
				list: null,
				name: message,
				message: formatMessage(message),
			})
		);
	};
	const onSuccess = (message) => {
		dispatch(
			addEvent({
				severity: "success",
				type: "notification",
				list: null,
				name: message,
				message: formatMessage(message),
			})
		);
	};

	const onEvent = (event) => {
		dispatch(
			addEvent({
				...event,
				message: formatMessage(event.message),
			})
		);
	};

	return { onError, onSuccess, onEvent, formatMessage };
};

export const useItemsTranslation = (ns) => {
    const { t } = useTranslation(ns);

    return (items, path, slug, key) => items.map(item => {
        const formattedPath = path.includes("{{slug}}") ? path.replace("{{slug}}", item[slug]) : `${path}.${item[slug]}`
        return {
            ...item,
            [key]: t(`${formattedPath}`)
        }
    })
}
