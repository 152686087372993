import React from "react";
import { TableRow, TableCell } from "@mui/material";
import _ from "lodash"

const Item = props => {
	const { headers, items, onSelect = () => {}, height, searchQuery, selected, disabled, loading } = props;

	const inners = [];
	for (const col in headers) {
		let label = _.get(items, col);
		if (searchQuery && label && typeof label === "string" && !headers[col].render) {
			label = label.toString().replace(/<\/?[^>]+(>|$)/g, "");
			const indx = label
				.toString()
				.toLowerCase()
				.indexOf(searchQuery.toLowerCase());
			if (indx !== -1) {
				const qu = label.toString().substr(indx, searchQuery.length);
				label = label.toString().replace(qu, `<mark>${qu}</mark>`);
			}
		}
		inners.push(
			<TableCell
				key={Math.random() * 1000}
				sx={[
					
					{ 
						position: headers[col]?.fixed ? "sticky" : "initial", 
						left: headers[col]?.fixed ? "0px" : "unset", 
						height, p: 1, 
						borderRight: "1px solid", 
						borderRightColor: "background.border" ,
						backgroundColor: items.id === selected ? "primary.light" : "background.paper",
						backgroundClip: "padding-box"

					},
					() => ({ "&:last-of-type": { borderRight: "none" } })
				]}
			>
				{typeof label === "string" && !headers[col].render ? <span dangerouslySetInnerHTML={{ __html: label }} /> : <span>{
				headers[col]?.render && !loading ? headers[col].render(label, items) : label
				}</span>}
			</TableCell>
		);
	}
	return (
		<TableRow
			onClick={() => {
				onSelect(items.id);
			}}
			sx={{
				cursor: disabled ? "default" : "pointer",
			}}
		>
			{inners}
		</TableRow>
	);
};

export default Item;
