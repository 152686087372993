import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getBaseQueryWithAuthorization from "../baseQuery";

export const filtersAPI = createApi({
	reducerPath: "filters/api",
	baseQuery: fetchBaseQuery(getBaseQueryWithAuthorization("filters")),
	keepUnusedDataFor: 30,
	endpoints: build => ({
		findFilters: build.query({
			query: params => ({
				url: "find",
				params
			}),
			transformResponse: response => response.payload,
		}),
	})
});

export const {
	useFindFiltersQuery,
    useLazyFindFiltersQuery
} = filtersAPI;

export default filtersAPI;